import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrivalCard from "../../../assests/img/logo.png";
import FeePage from "../../../assests/img/fee-page.png";
import Disclaimer from "../../../assests/img/disclaimer.png";
import OrderConfirmation from "../../../assests/img/order-confirmation-mail.png";
import ThankYouPage from "../../../assests/img/thankyou-page.png";
import CompleteOrderConfirmation from "../../../assests/img/complete-order-confirmation-mail.png";
import { DefenceContainer } from "./style";
import { Link } from "react-router-dom";
import moment from "moment";
import { GetDefencePackPdf } from "../../../redux/orderSlice";

const DefenceContent = ({ defencePackName }) => {
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.OrderDetails);

  const handleDefencePack = (e) => {
    let data = {
      orderId: OrderDetails?.order_id,
      defencePackType: Number(e.target.id),
    };
    dispatch(GetDefencePackPdf(data))
      .unwrap()
      .then(async (res) => {
        if (res !== null) {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL);
        }
      });
  };

  return (
    <DefenceContainer>
      <div>
        <img src={ArrivalCard} alt="Arrivalcard" className="arrival-img" />
      </div>
      <hr />
      <div className="header">
        <p> Electric Doc Services LLC</p>
        <p> 1800 Pembrook Dr. </p>
        <p>Suite 300</p>
        <p> Orlando </p>
        <p> FL, 32810</p>

        <p>Tel: +1 (407) 955-9631</p>

        <p>inquiries@sg-arrivalcard.online</p>

        <p>EIN: 85-1546748</p>
      </div>

      <div className="main">
        <p>March 02, 2023</p>
        <p>{OrderDetails?.transaction_id}</p>
        <p>Dear {OrderDetails?.merchant_name}</p>
        <br />
        <br />
        <p>
          {" "}
          The customer was provided with the service that we advertise, and the
          customer received the SG arrival card confirmation. Please find
          enclosed all the details regarding our service. This is the process
          through in which a customer must proceed to place an order with us:
        </p>
        <br />
        <div className="list-content">
          <p>
            {" "}
            <b>(1.)</b>Prior to submitting the online application for the SG
            arrival card, a customer who visits our website and wants to learn
            more about our services before proceeding can view the costs of our
            services as well as the additional benefits we provide for our
            customers.<b> (Page 2).</b>
          </p>
          <br />

          <p>
            {" "}
            In fact, we even have a disclaimer on our site which states that{" "}
            <b>
              (Our service is not connected to or affiliated with the Singapore
              Government),
            </b>{" "}
            so the customer is aware of the fact that we are a third-party
            service provider. <b>(Page 2).</b>
          </p>
          <br />

          <p>
            {" "}
            <b> (2.) </b>When the customer has completed and paid for the online
            application, we are then able to see the{" "}
            <b>Order ID, Date and Time, IP address, Time Zone location,</b> as
            well as the<b> signature.</b> With all of this information, we are
            then able to confirm how many times the application was downloaded.
            <b> ( Page 3,4,5 and 6 )</b>
          </p>
        </div>
        <br />
        <p>
          We pass all customer information like
          <b> Device IP address, email, phone numbers</b> and <b>addresses </b>
          to Payees, if these were incorrect or suspected fraud these payments
          would be declined.
        </p>
        <br />
        <div className="container-box">
          <h5>
            {" "}
            NEITHER A REFUND NOR A COMPLAINT HAS BEEN SUBMITTED BY THIS
            CUSTOMER, SO THIS FIRST CHARGEBACK SHOULD BE DENIED ON THE BASIS
            THAT NO REFUND HAS BEEN REQUESTED.{" "}
          </h5>
        </div>
        <br /> <br />
        <p>
          <span>Fees</span> (Before apply page)
        </p>
        <p>
          Fee page with spans to our additional benefits and Government site.
        </p>
        <img src={FeePage} alt="FeePage" />
        <br /> <br />
        <p>
          <span>Disclaimer</span> (Before apply page)
        </p>
        <p>Homepage with various contact options spans and disclaimer.</p>
        <img src={Disclaimer} alt="Disclaimer" />
        <br /> <br />
        <br />
        <p>
          <span>Payment Confirmation</span> (After completion)
        </p>
        <p>Payment confirmation page.</p>
        <img src={ThankYouPage} alt="ThankYouPage" />
        <br /> <br />
        <br />
        <span>Order Confirmation</span>
        <br />
        <p>
          In the confirmation email that is sent to the customer after the order
          has been paid for, there is a span to our terms and conditions. At
          this stage the customer is still able to contact us if they have any
          questions, concerns or require a refund.
        </p>
        <img src={OrderConfirmation} alt="OrderConfirmation" />
        <br /> <br />
        <br />
        <span>Completed Order Confirmation</span>
        <br />
        <p>
          Once the SG arrival card application has been approved, we email the
          customer a span to our portal where they can download their approved
          SG arrival card and be ready to travel to Singapore by air.
        </p>
        <img src={CompleteOrderConfirmation} alt="CompleteOrderConfirmation" />
        <br /> <br />
        <br />
        <span>Completed Order</span>
        <br />
        <p>
          In addition to emailing the customer an SG arrival card approval in a
          PDF format, we track how many times it has been downloaded. As the
          customer accepts our terms and conditions, we can view the digital
          signature the customer has entered. Sometimes the signature is not
          perfect due to the device being used, but we can verify that we have
          the date, time, IP address, and location of the customer.
        </p>
        <div className="download-history">
          {defencePackName !== "defencePack1" ? (
            <div className="card-body">
              <div className="card-header">
                <h5> Download History</h5>
                <div className="card-header-right">
                  <ul className="list-unstyled card-option">
                    <li>
                      <i className="feather icon-maximize full-card"></i>
                    </li>
                    <li>
                      <i className="feather icon-minus minimize-card"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-block">
                <div className="table-responsive" style={{ padding: "10px" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Order ID</th>
                        <th>Date & Time</th>
                        <th>Downloaded File</th>
                        <th>IP Address</th>
                        <th>Timezone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {OrderDetails?.downloadHistory ? (
                        OrderDetails?.downloadHistory?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item?.order_id}</td>
                              <td>{item?.create_ts}</td>
                              {item?.order_id && (
                                <td>
                                  <Link
                                    to="#"
                                    download
                                    className="btn btn-success"
                                  >
                                    Download File
                                  </Link>
                                </td>
                              )}

                              <td>{item?.ip}</td>
                              <td>{item?.timezone}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4" align="center">
                            No record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <>
              {OrderDetails?.mailHistory?.length !== 0 && (
                <div className="card-body">
                  <div className="card-header">
                    <h5> Email History</h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>
                          <i className="feather icon-maximize full-card"></i>
                        </li>
                        <li>
                          <i className="feather icon-minus minimize-card"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div
                      className="table-responsive"
                      style={{ padding: "10px" }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order ID</th>
                            <th>Download</th>
                            <th>Date & Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {OrderDetails?.mailHistory?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.order_id}</td>
                                <td>
                                  <div className="form-group">
                                    <a
                                      href={
                                        OrderDetails?.length !== 0
                                          ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.uploadDoc[0]?.file1}`
                                          : "#"
                                      }
                                      // target="_blank"
                                      download
                                      className="btn btn-success"
                                    >
                                      Download File
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  {moment(item?.create_ts).format(
                                    "DD-MM-YYYY h:mm:ss"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="sign-block">
          <img
            src={`https://sg-arrivalcard.online/${OrderDetails?.customer_sign}`}
            alt="sign"
          />
        </div>
        <br />
        <br />
        <span>Pictured below</span>
        <br />
        <p>
          {" "}
          This is the approved SG arrival card in PDF format, which the
          customerdownloaded from our portal.
        </p>
        <div className="sign-block">
          <img
            src={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.uploadDoc[0]?.file1}`}
            alt="img"
          />
        </div>
        <br />
        <br />
      </div>
      {defencePackName !== "defencePack1" ? (
        <button type="button" onClick={handleDefencePack} id="1">
          Print Defence Pack 1
        </button>
      ) : (
        <button type="button" onClick={handleDefencePack} id="2">
          Print Defence Pack 2
        </button>
      )}
    </DefenceContainer>
  );
};

export default DefenceContent;
