import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 10px 20rem 100px 20rem;

  @media (max-width: 1400px) {
    padding: 10px 8rem 100px 8rem;
  }

  @media (max-width: 1000px) {
    padding: 10px 5rem 100px 5rem;
  }

  @media (max-width: 576px) {
    padding: 10px 2rem 100px 2rem;
  }
`;

export const StyledContact = styled.div`
  background: #f7f7f7;
  padding: 13px 42px;

  & h5 {
    color: #8d2029;
    padding-bottom: 20px;
  }

  & label {
    display: block;
    font-weight: bold;
    width: max-content;
  }

  & input {
    display: block;
    color: #343a40;
    border: 1px solid;
    border-radius: 0;
    width: 100%;
    height: 60px;
    border: 1px solid #ededed;
    background: #fff;
    padding-left: 20px;
    box-shadow: inset 0 0 10px #051d28;
    margin-bottom: 25px;
  }

  & textarea {
    width: 100%;
    height: 150px;
    padding-top: 15px;
    border: none;
  }

  .asterisk {
    color: red;
  }
`;

export const MainDiv = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: row;

  @media (max-width: 575px) {
    display: block;
  }
`;

export const FirstDiv = styled.div`
  width: -webkit-fill-available;
`;

export const StyledButton = styled.div`
  padding: 2rem 0;

  & button {
    background: #8d2029;
    padding: 20px;
    width: 100%;
    border-radius: 5px;
    color: #fff;
    justify-content: center;
    font-size: 14px;
    border: none;
    margin-bottom: 25px;
  }
`;
