import React from "react";
import { StyledDiv, StyledWrapper } from "./style";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
  return (
    <StyledWrapper>
      <h2>REFUND POLICY</h2>

      <StyledDiv>
        <h5>Background</h5>
        <p>
          For inquiries relating to your SG Arrival Card application: in the
          first instance please send us an email to:
          <Link to="#">inquiries@sg-arrivalcard.online</Link>
        </p>
        <h6>Our Trading Address:</h6>
        <ul>
          <li>sg-arrivalcard.online, </li>
          <li>1800 Pembrook Dr. </li>
          <li>Suite 300, Orlando </li>
          <li>FL 32810 </li>
        </ul>
        <h5>Refund Policy</h5>

        <h5>1. REFUND & COMPLAINTS</h5>
        <p>
          Users of our services are protected by United States of America &
          International law. The service we offer is a digital personalized
          service, by proceeding to submit an SG Arrival Card application you
          agree to waive your cancellation rights.
        </p>

        <h5>2. REFUNDS & COMPLAINTS PROCEDURE</h5>
        <p>
          All refund requests and formal complaints must be made via email to:
          <Link to="#">inquiries@sg-arrivalcard.online</Link>
        </p>

        <h5>3. TIME FRAME</h5>
        <p>
          We aim to process successfully submitted SG Arrival Card applications
          within 72 hours. However we are not liable or responsible for delays
          caused in processing of the SG Arrival Card application. We do not
          advise submitting an SG Arrival Card application via
          www.sg-arrivalcard.online within 72 hours of travel.
        </p>

        <h5>4. SERVICE COSTS AND FEES</h5>
        <p>
          By accepting these terms and conditions and after successful payment,
          you are instructing sg-arrivalcard.online data processing team to
          process your SG Arrival Card application on your behalf. The <b>$60.00 -
          $79.99</b> USD fee is charged per applicant and includes the following
          benefits; Confirmation Of Application,
        </p>
        <p>
          Admission and Error Checks On Application, Reimbursement For Declined
          SG Arrival Card Applications, 24/7 Email Support and Advice. We as a
          merchant shall be under no liability whatsoever in respect of any loss
          or damage arising directly or indirectly out of the decline of
          authorization for any Transaction, on Account of the Cardholder having
          exceeded the preset limit mutually agreed by us with our acquiring
          bank from time to time.
        </p>

        <h5> 5. NO WAIVER</h5>
        <p>
          In the event that any party to these Terms and Conditions fails to
          exercise any right or remedy contained herein, this shall not be
          construed as a waiver of that right or remedy.
        </p>

        <h5>6. NOTICES</h5>
        <p>
          All notices / communications shall be given to us either by post to
          our Premises or by email to <Link to="#">inquiries@sg-arrivalcard.online</Link> Such
          notice will be deemed received 3 days after posting if sent by first
          class post, the day of sending if the email is received in full on a
          business day and on the next business day if the email is sent on a
          weekend or public holiday.
        </p>
        <p>
          www.sg-arrivalcard.online is a trading name of sg-arrivalcard.online
          is a company registered in United States of America. The registered
          office of sg-arrivalcard.online is 1800 Pembrook Dr. Suite 300,
          Orlando, FL 32810 United States
        </p>
      </StyledDiv>
    </StyledWrapper>
  );
};

export default RefundPolicy;
