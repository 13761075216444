import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 100px 0px;

  & h2 {
    text-align: center;
  }
`;

export const StyledChild = styled.div`
  padding: 2rem 15rem;

  @media (max-width: 710px) {
    padding: 2rem 10rem;
}

@media (max-width: 610px) {
  padding: 2rem 3rem;
}

  @media (max-width: 482px) {
    padding: 2rem 0rem;
}


`;

export const CardContainer = styled.div`
  margin: 10px;
`;

export const StyledBenefit = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 234px;
  margin-top: 30px;
  display:flex;

  @media (max-width:992px){
    display: contents;
   }


  & div{
    height: 234px;
    width: 27rem;
    border: 1px solid rgba(0, 0, 0, 0.125);

    @media (max-width:992px){
      width: 29.2rem;
     }

     @media (max-width: 530px) {
      width: 25rem;
  }
  
  @media (max-width: 415px) {
    width: 22rem;
  }

  @media (max-width: 365px) {
    width: 10rem;
  }


}

& img{
    width:128px;
     height:128px;
      display: block;
    margin: 0 auto;
     padding-top:20px;
}

& p{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
}
  `;
