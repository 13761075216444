import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrdersDetails,
  resendMail,
  uploadVisaDocument,
} from "../../redux/orderSlice";
import { Container } from "./style";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import AddComment from "./AddComment/AddComment";
import moment from "moment";
import DefenceContent from "./DefenceContent/DefenceContent";
import TransactionModal from "./TransactionModal/TransactionModal";

const ViewOrders = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.OrderDetails);
  const [show, setShow] = useState(false);
  const [showDefence, setShowDefence] = useState(false);
  const [showTransaction, SetShowTransaction] = useState(false);
  const [showDefencePack, setShowDefencePack] = useState(false);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });
  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrdersDetails(orderId));
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("order_id", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      dispatch(uploadVisaDocument(data))
        .unwrap()
        .then((res) => {
          resetForm();
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrdersDetails(orderId));
          }
        });
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      order_id: OrderDetails?.order_id,
      email: emailId,
    };
    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrdersDetails(orderId));
        }
      });
  };
  return (
    <>
      <Container>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card card-details">
            <div className="header">
              <h2>
                <strong>Order Details</strong>
              </h2>
            </div>
            <div className="body">
              <div className="card mb-3">
                <div className="card-header">
                  <i className="fas fa-table"></i>Order Summary
                  <div className="showing_entry-parent ">
                    <div className=""></div>
                  </div>
                </div>
                {OrderDetails?.mailHistory?.length !== 0 && (
                  <div className="card-body">
                    <div className="card-header">
                      <h5> Email History</h5>
                      <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                          <li>
                            <i className="feather icon-maximize full-card"></i>
                          </li>
                          <li>
                            <i className="feather icon-minus minimize-card"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-block">
                      <div
                        className="table-responsive"
                        style={{ padding: "10px" }}
                      >
                        <table className="table table-striped table-bordered myTable showhistory">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Order ID</th>
                              <th>Download</th>
                              <th>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {OrderDetails?.mailHistory?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item?.order_id}</td>
                                  <td>
                                    <div className="form-group">
                                      <a
                                        href={
                                          OrderDetails?.length !== 0
                                            ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.uploadDoc[0]?.file1}`
                                            : "#"
                                        }
                                        // target="_blank"
                                        download
                                        className="btn btn-success"
                                      >
                                        Download File
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    {moment(item?.create_ts).format(
                                      "MM-DD-YYYY h:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <form className="form-inline">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                              value={emailId}
                              onChange={(e) => setEmailId(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="download_btn"
                              onClick={handleResendMail}
                            >
                              Resend Email
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
                <div className="card-body">
                  <div className="card-header">
                    <h5>Upload Document</h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>
                          <i className="feather icon-maximize full-card"></i>
                        </li>
                        <li>
                          <i className="feather icon-minus minimize-card"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div className="table-responsive">
                      <form onSubmit={handleSubmit}>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="form-group">
                                  <label>Order ID</label>
                                  <input
                                    type="text"
                                    name="orderId"
                                    className="form-control"
                                    placeholder="Order ID"
                                    disabled
                                    value={values?.orderId}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <label>Email ID</label>
                                  <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={values?.email}
                                    onChange={handleChange}
                                  />
                                  <p>{errors.email}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="form-group">
                                  <label>PDF</label>
                                  <input
                                    type="file"
                                    className="form-control required"
                                    id="fileUpload"
                                    name="fileUpload"
                                    accept="image/*"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "fileUpload",
                                        e.currentTarget.files[0]
                                      )
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <p>{errors.fileUpload}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="form-group">
                                  <a
                                    href={
                                      OrderDetails?.length !== 0
                                        ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.uploadDoc[0]?.file1}`
                                        : "#"
                                    }
                                    // target="_blank"
                                    download
                                    className="blue-btn"
                                  >
                                    Download File
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <button type="submit" className="blue-btn">
                                  Submit
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="order-detail table table-bordered"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        {OrderDetails?.order_id && (
                          <tr>
                            <th style={{ width: "50%" }}>Order ID</th>
                            <td>{OrderDetails?.order_id}</td>
                          </tr>
                        )}

                        {OrderDetails?.processing_mode && (
                          <tr>
                            <th style={{ width: "50%" }}>Processing Type</th>
                            <td>{OrderDetails?.processing_mode}</td>
                          </tr>
                        )}

                        <tr
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                          }}
                        >
                          <th style={{ width: "50%" }}>Personal Information</th>
                          <td></td>
                        </tr>

                        {OrderDetails?.full_name && (
                          <tr>
                            <th style={{ width: "50%" }}>Full Name</th>
                            <td>{OrderDetails?.full_name}</td>
                          </tr>
                        )}

                        {OrderDetails?.passport_number && (
                          <tr>
                            <th style={{ width: "50%" }}>Passport number</th>
                            <td>{OrderDetails?.passport_number}</td>
                          </tr>
                        )}

                        {OrderDetails?.date_of_birth && (
                          <tr>
                            <th style={{ width: "50%" }}>Date of birth</th>
                            <td>
                              {moment(OrderDetails?.date_of_birth).format(
                                "DD-MM-Y"
                              )}
                            </td>
                          </tr>
                        )}

                        {OrderDetails?.nationality && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Nationality (Country)
                            </th>
                            <td>{OrderDetails?.nationality}</td>
                          </tr>
                        )}

                        {OrderDetails?.sex && (
                          <tr>
                            <th style={{ width: "50%" }}>Gender</th>
                            <td>{OrderDetails?.sex}</td>
                          </tr>
                        )}

                        {OrderDetails?.passport_expiry_date && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Date of Passport Expiry
                            </th>
                            <td>
                              {moment(
                                OrderDetails?.passport_expiry_date
                              ).format("DD-MM-Y")}
                            </td>
                          </tr>
                        )}

                        {OrderDetails?.place_of_residence && (
                          <tr>
                            <th style={{ width: "50%" }}>Place of Residence</th>
                            <td>{OrderDetails?.place_of_residence}</td>
                          </tr>
                        )}

                        {OrderDetails?.place_of_birth && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Country/Place of birth
                            </th>
                            <td>{OrderDetails?.place_of_birth}</td>
                          </tr>
                        )}

                        {OrderDetails?.email && (
                          <tr>
                            <th style={{ width: "50%" }}>Email</th>
                            <td>{OrderDetails?.email}</td>
                          </tr>
                        )}

                        {OrderDetails?.country_code && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Country/Region code
                            </th>
                            <td>{OrderDetails?.country_code}</td>
                          </tr>
                        )}

                        {OrderDetails?.phone && (
                          <tr>
                            <th style={{ width: "50%" }}>Phone Number</th>
                            <td>{OrderDetails?.phone}</td>
                          </tr>
                        )}

                        {OrderDetails?.arrival_date && (
                          <tr>
                            <th style={{ width: "50%" }}>Date of Arrival</th>
                            <td>
                              {moment(OrderDetails?.arrival_date).format(
                                "DD-MM-Y"
                              )}
                            </td>
                          </tr>
                        )}

                        {OrderDetails?.departure_date && (
                          <tr>
                            <th style={{ width: "50%" }}>Date of Departure</th>
                            <td>
                              {moment(OrderDetails?.departure_date).format(
                                "DD-MM-Y"
                              )}
                            </td>
                          </tr>
                        )}

                        <tr
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                          }}
                        >
                          <th style={{ width: "50%" }}>Trip Information</th>
                          <td></td>
                        </tr>

                        {OrderDetails?.travel_mode && (
                          <tr>
                            <th style={{ width: "50%" }}>Mode of Travel</th>
                            <td>{OrderDetails?.travel_mode}</td>
                          </tr>
                        )}

                        {OrderDetails?.travel_mode_details && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Mode of Travel(Details)
                            </th>
                            <td>{OrderDetails?.travel_mode_details}</td>
                          </tr>
                        )}

                        {OrderDetails?.flight_code && (
                          <tr>
                            <th style={{ width: "50%" }}>Flight Code</th>
                            <td>{OrderDetails?.flight_code}</td>
                          </tr>
                        )}

                        {OrderDetails?.flight_number && (
                          <tr>
                            <th style={{ width: "50%" }}>Flight Number</th>
                            <td>{OrderDetails?.flight_number}</td>
                          </tr>
                        )}

                        {OrderDetails?.travel_mode_details_land && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Mode of Travel (Land Details)
                            </th>
                            <td>{OrderDetails?.travel_mode_details_land}</td>
                          </tr>
                        )}

                        {OrderDetails?.vecle_number && (
                          <tr>
                            <th style={{ width: "50%" }}>Vehicle Number</th>
                            <td>{OrderDetails?.vecle_number}</td>
                          </tr>
                        )}

                        {OrderDetails?.travel_mode_details_sea && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Mode of Travel (SEA Details)
                            </th>
                            <td>{OrderDetails?.travel_mode_details_sea}</td>
                          </tr>
                        )}

                        {OrderDetails?.vessel_name && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Cruise / Vessel Name
                            </th>
                            <td>{OrderDetails?.vessel_name}</td>
                          </tr>
                        )}

                        <tr
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                          }}
                        >
                          <th style={{ width: "50%" }}>
                            Type of Accommodation in Singapore
                          </th>
                          <td></td>
                        </tr>

                        {OrderDetails?.accommodation && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Type of Accommodation
                            </th>
                            <td>{OrderDetails?.accommodation}</td>
                          </tr>
                        )}

                        {OrderDetails?.hotel_name && (
                          <tr>
                            <th style={{ width: "50%" }}>Name of Hotel</th>
                            <td>{OrderDetails?.hotel_name}</td>
                          </tr>
                        )}

                        {OrderDetails?.other_hotel_name && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Name of Hotel(Other)
                            </th>
                            <td>{OrderDetails?.other_hotel_name}</td>
                          </tr>
                        )}

                        {OrderDetails?.accomodation_other && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Accommodation (Others)
                            </th>
                            <td>{OrderDetails?.accomodation_other}</td>
                          </tr>
                        )}

                        {OrderDetails?.unit_number && (
                          <tr>
                            <th style={{ width: "50%" }}>Unit Number</th>
                            <td>{OrderDetails?.unit_number}</td>
                          </tr>
                        )}

                        {OrderDetails?.last_city && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Last City / Port of Embarkation Before Singapore
                            </th>
                            <td>{OrderDetails?.last_city}</td>
                          </tr>
                        )}

                        {OrderDetails?.next_city && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Next City / Port of Disembarkation After Singapore
                            </th>
                            <td>{OrderDetails?.next_city}</td>
                          </tr>
                        )}

                        {OrderDetails?.used_passport && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Have you ever used a passport under different name
                              to enter Singapore?
                            </th>
                            <td>{OrderDetails?.used_passport}</td>
                          </tr>
                        )}

                        {OrderDetails?.different_name && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Name different from current passport
                            </th>
                            <td>{OrderDetails?.different_name}</td>
                          </tr>
                        )}

                        <tr
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                          }}
                        >
                          <th style={{ width: "50%" }}>Health Declaration</th>
                          <td></td>
                        </tr>

                        {OrderDetails?.previous_visit && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Have you visited any of the listed countries in
                              Africa <br /> or Latin America in the past 6 days
                              prior to your arrival in Singapore
                            </th>
                            <td>{OrderDetails?.previous_visit}</td>
                          </tr>
                        )}

                        {OrderDetails?.symptoms && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Did you start to have any symptoms in the last 7
                              days: <br /> fever, rash, cough, runny nose, sore
                              throat, loss of sense of smell, or shortness of
                              breath?
                            </th>
                            <td>{OrderDetails?.symptoms}</td>
                          </tr>
                        )}

                        {OrderDetails?.current_symptoms && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Do you currently have fever, cough, shortness of
                              breath, headache, or vomiting AND have <br />{" "}
                              visited Africa or the Middle East in the past 14
                              days prior to your arrival in Singapore?
                            </th>
                            <td>{OrderDetails?.current_symptoms}</td>
                          </tr>
                        )}

                        {OrderDetails?.covid_vaccinated && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              Are you fully vaccinated with WHO-EUL vaccines
                              against COVID-19?
                            </th>
                            <td>{OrderDetails?.covid_vaccinated}</td>
                          </tr>
                        )}
                        {OrderDetails?.upload_vaiccinacion && (
                          <>
                            <tr
                              style={{
                                backgroundColor: "#000",
                                color: "#fff",
                              }}
                            >
                              <th style={{ width: "50%" }}>
                                Vaccination certificate
                              </th>
                              <td></td>
                            </tr>

                            <tr>
                              <th style={{ width: "50%" }}>
                                View vaccination certificate:
                              </th>
                              <td>
                                <Link
                                  to={OrderDetails?.upload_vaiccinacion}
                                  target="_blank"
                                >
                                  Click Here
                                </Link>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                          }}
                        >
                          <th style={{ width: "50%" }}>Signature</th>
                          <td></td>
                        </tr>
                        {OrderDetails?.customer_sign && (
                          <tr>
                            <th style={{ width: "50%" }}>
                              View Digital Signature:
                            </th>
                            <td>
                              <Link
                                to={`https://sg-arrivalcard.online/${OrderDetails?.customer_sign}`}
                                target="_blank"
                              >
                                Click Here
                              </Link>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-header">
                    <h5> Download History</h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>
                          <i className="feather icon-maximize full-card"></i>
                        </li>
                        <li>
                          <i className="feather icon-minus minimize-card"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div
                      className="table-responsive"
                      style={{ padding: "10px" }}
                    >
                      <table className="table table-striped table-bordered myTable showhistory">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order ID</th>
                            <th>Date & Time</th>
                            <th>Downloaded File</th>
                            <th>IP Address</th>
                            <th>Timezone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {OrderDetails?.downloadHistory ? (
                            OrderDetails?.downloadHistory?.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item?.order_id}</td>
                                    <td>{item?.create_ts}</td>
                                    {item?.order_id && (
                                      <td>
                                        <Link
                                          to="#"
                                          download
                                          className="btn btn-success"
                                        >
                                          Download File
                                        </Link>
                                      </td>
                                    )}

                                    <td>{item?.ip}</td>
                                    <td>{item?.timezone}</td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan="4" align="center">
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <AddComment />
                <div className="card-body">
                  <div className="card-header">
                    <h5>Comment History</h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>
                          <i className="feather icon-maximize full-card"></i>
                        </li>
                        <li>
                          <i className="feather icon-minus minimize-card"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div
                      className="table-responsive"
                      style={{ padding: "10px" }}
                    >
                      <table className="table table-striped table-bordered myTable showhistory">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order ID</th>
                            <th>Date & Time</th>
                            <th>Team</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {OrderDetails?.remark ? (
                            OrderDetails?.remark?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{item?.order_id}</td>
                                  <td>
                                    {item?.us_date}
                                    {"  "}
                                    {item?.us_time}
                                  </td>
                                  <td>{item?.remark_name}</td>
                                  <td>
                                    {selectedData.showData &&
                                    selectedData.dataId === item.id ? (
                                      <div
                                        rel="40"
                                        className="btn btn-success"
                                        onClick={() =>
                                          setSelectedData({
                                            showData: !selectedData.showData,
                                            dataId: item?.id,
                                          })
                                        }
                                      >
                                        {" "}
                                        Hide
                                      </div>
                                    ) : (
                                      <div
                                        rel="40"
                                        onClick={() =>
                                          setSelectedData({
                                            showData: !selectedData.showData,
                                            dataId: item?.id,
                                          })
                                        }
                                        className="btn btn-success"
                                      >
                                        {" "}
                                        Show
                                      </div>
                                    )}
                                    <br />
                                    {selectedData.showData &&
                                      selectedData.dataId === item.id && (
                                        <div id="40">
                                          <textarea
                                            className="form-control"
                                            defaultValue={item?.remark}
                                          ></textarea>
                                          <b>
                                            Whatsapp:
                                            {item?.whatsapp
                                              ? item?.whatsapp
                                              : "N"}
                                          </b>
                                          <b>
                                            Telephone:
                                            {item?.telephone
                                              ? item?.telephone
                                              : "N"}
                                          </b>
                                          <b>
                                            Refund:
                                            {item?.status_refund
                                              ? item?.status_refund
                                              : "N"}
                                          </b>
                                          <b>
                                            Voided:
                                            {item?.voided ? item?.voided : "N"}
                                          </b>
                                          <b>
                                            Chargeback:
                                            {item?.chargeback
                                              ? item?.chargeback
                                              : "N"}
                                          </b>
                                        </div>
                                      )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4" align="center">
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="blue-btn"
                  data-toggle="modal"
                  data-target="#refundModal"
                  onClick={() => setShow(true)}
                >
                  View Email Content
                </button>
                {OrderDetails?.process_status === "Completed" && (
                  <>
                    <button
                      type="button"
                      className="blue-btn"
                      data-toggle="modal"
                      data-target="#defencePackModal"
                      onClick={() => setShowDefence(true)}
                    >
                      Defence Pack 1
                    </button>
                    <button
                      type="button"
                      className="blue-btn"
                      data-toggle="modal"
                      data-target="#defencePackModal1"
                      onClick={() => setShowDefencePack(true)}
                    >
                      Defence Pack 2
                    </button>
                    <button
                      type="button"
                      className="blue-btn"
                      data-toggle="modal"
                      data-target="#transactionModal"
                      onClick={() => SetShowTransaction(true)}
                    >
                      Enter Transaction ID
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <MyModal
          show={show}
          close={setShow}
          modalHeadTitle={"Email Content"}
          showFooter={true}
          primaryButtonName={"Close"}
          secondaryButtonName={"Send Whatsapp Message"}
          OrderDetails={OrderDetails}
        >
          <EmailContent />
        </MyModal>
        <MyModal
          show={showDefence}
          close={setShowDefence}
          modalHeadTitle={"Defence Pack Content"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <DefenceContent defencePackName={null} />
        </MyModal>
        <MyModal
          show={showDefencePack}
          close={setShowDefencePack}
          modalHeadTitle={"Defence Pack Content"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <DefenceContent defencePackName={"defencePack1"} />
        </MyModal>
        <MyModal
          show={showTransaction}
          close={SetShowTransaction}
          size={"s"}
          modalHeadTitle={"Enter Defence Pack Details:"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <TransactionModal SetShowTransaction={SetShowTransaction} />
        </MyModal>
      </Container>
    </>
  );
};

export default ViewOrders;
