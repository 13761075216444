import React from "react";
import { Link } from "react-router-dom";
import {
  StyledArrival,
  StyledLink,
  StyledCardDiv,
  StyledCard,
  StyledSection,
  StyleScroll,
  StyledDiv,
  ChildDiv,
  Container,
  TableContainer,
  Table,
  FooterDiv,
  StyledList,
  HomeFooter,
  StyledContainer,
} from "./style";
import SingaporeImg from "../../assests/img/turkeymap.png";
import cardlogo from "../../assests/img/logo.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PassportImg from "../../assests/img/passport.png";
import TravelImg from "../../assests/img/travel.png";
import ArrivalImg from "../../assests/img/arrival.png";
import ContactImg from "../../assests/img/contact.png";
import { BiChevronRight } from "react-icons/bi";

const Home = () => {
  return (
    <StyledArrival>
      <h3>Singapore Arrival Card Online in 3 easy steps!</h3>
      <StyledLink>
        <Link to="#">
          Submit The SG Arrival Card
          <br />
          With Electronic health Declaration
          <br />
          CLICK HERE
        </Link>
      </StyledLink>

      <StyledCardDiv>
        <StyledCard>
          <div>
            <div>
              <h5>Apply online</h5>
              <p>Complete and submit the SG Arrival Card Application.</p>
              <Link to="#" className="card-link">
                Apply Now
              </Link>
            </div>
          </div>
        </StyledCard>
        <StyledCard>
          <div>
            <div>
              <h5>Pay processing fee online</h5>
              <p>Pay the SG Arrival Card online using Credit/Debit card</p>
              <Link to="#" className="card-link">
                Apply Now
              </Link>
            </div>
          </div>
        </StyledCard>
        <StyledCard>
          <div>
            <div>
              <h5>Receive SG Arrival Card online</h5>
              <p>SG Arrival Card will be sent to your e-mail</p>
              <Link to="#" className="card-link">
                Apply Now
              </Link>
            </div>
          </div>
        </StyledCard>
      </StyledCardDiv>

      <StyledSection>
        <div>
          <h5>Singapore Electronic Arrival Card</h5>
          <br />
          <p>
            It is a single entry electronic admission document a version of the
            paper disembarkation/embarkation (DE) card (or commonly known as the
            white card) which foreign travelers are required to complete online
            before arrival date in Singapore and submit on arrival, to avoid
            wasting time and paper at the airport.
          </p>
          <br />
        </div>
      </StyledSection>
      <StyleScroll>
        <StyledContainer>
          <OwlCarousel className="owl-carousel" nav autoPlay={true}>
            <div className="item">
              <div className="img_div">
                <img src={ContactImg} alt="contact" />
              </div>
              <h4>Contact information in Singapore</h4>
            </div>
            <div className="item">
              <div className="img_div">
                <img src={TravelImg} alt="travel" />
              </div>
              <h4>Travel Information</h4>
            </div>
            <div className="item">
              <div className="img_div">
                <img src={ArrivalImg} alt="arrival" />
              </div>
              <h4>Entry & Departure date</h4>
            </div>
            <div className="item">
              <div className="img_div">
                <img src={PassportImg} alt="passport" />
              </div>
              <h4>Passport with 6-month minimum validity</h4>
            </div>
          </OwlCarousel>
        </StyledContainer>

        <StyledDiv>
          <div>
            <span>
              <img src={SingaporeImg} alt="singapore" />
            </span>
          </div>
          <ChildDiv>
            <h5>SG Arrival Card Application for Singapore</h5>
            <p>
              A Singapore Arrival Card is required for all visitors entering the
              country. To get an Electronic Arrival Card for Singapore, all
              eligible nationals should fill out an easy and straightforward
              online application.
            </p>
            <p>
              <b>Note:</b> All travelers arriving in Singapore must submit a
              health declaration form to be let through immigration checks at
              the border. The health declaration can be completed online as part
              of the Arrival Card e-Service.
            </p>
          </ChildDiv>
        </StyledDiv>
      </StyleScroll>

      <Container>
        <h5>The SG Arrival Card for Singaporean</h5>

        <p>
          Singapore's government is going paperless by introducing the
          Electronic SG Arrival Card instead of the paper
          Disembarkation/Embarkation Card (D/E Card). By using the new online
          application, visitors will be able to get a Singapore Electronic
          Arrival Card quickly and save time.
        </p>
        <p>
          Singapore Arrival Cards are linked to your passport through the
          Singapore Arrival System, so it's a good idea to store a copy on your
          phone before you leave.
        </p>
        <p>
          A Singapore Arrival Card and an approved Singapore visa are required
          for citizens of certain countries to enter the country. These can be
          obtained separately.
        </p>

        <h5>
          The following countries need a SG Arrival Card as well as a Singapore
          visa:
        </h5>

        <p>
          An evisa: Afghanistan, Algeria, Bangladesh, Egypt, Iran, Iraq, Jordan,
          Libya, Mali, Morocco, Nigeria, Pakistan, Saudi Arabia, Somalia, Sudan,
          Syrian Arab Republic, Tajikistan, Tunisia, Yemen
        </p>
        <p>
          A consular visa: Armenia, Azerbaijan, Belarus, People’s Republic of
          China, Georgia, India, Kyrgyzstan, People’s Republic of Korea,
          Kazakhstan, Moldova, Malaysia, Russia, Turkmenistan, Ukraine,
          Uzbekistan.
        </p>
        <p>
          Please contact the nearest Singapore Consulate or Embassy for more
          information about visas.
        </p>
      </Container>
      <TableContainer>
        <h5>Singapore Arrival Card eligible countries</h5>

        <p>
          SG Arrival Cards are available to citizens and nationals of
          visa-exempt and visa-required countries, including China, Indonesia,
          India, Australia, Malaysia, Japan, Philippines, Korea, United Kingdom,
          United States, Vietnam, and Thailand
        </p>
        <Table>
          <tbody>
            <tr>
              <td>Afghanistan</td>
              <td>Albania</td>
              <td>Algeria</td>
              <td>Andorra</td>
              <td>Angola</td>
            </tr>
            <tr>
              <td>Antigua and Barbuda</td>
              <td>Argentina</td>
              <td>Armenia</td>
              <td>Australia</td>
              <td>Austria</td>
            </tr>
            <tr>
              <td>Azerbaijan</td>
              <td>Bahamas</td>
              <td>Bahrain</td>
              <td>Bangladesh</td>
              <td>Barbados</td>
            </tr>
            <tr>
              <td>Belarus</td>
              <td>Belgium</td>
              <td>Belize</td>
              <td>Benin</td>
              <td>Bhutan</td>
            </tr>
            <tr>
              <td>Bolivia</td>
              <td>Bosnia and Herzegovina</td>
              <td>Botswana</td>
              <td>Brazil</td>
              <td>Brunei Darussalam</td>
            </tr>
            <tr>
              <td>Bulgaria</td>
              <td>Burkina Faso</td>
              <td>Burundi</td>
              <td>Cambodia</td>
              <td>Cameroon</td>
            </tr>

            <tr>
              <td>Canada</td>
              <td>Cape Verde</td>
              <td>Central African Republic</td>
              <td>Chad</td>
              <td>ChileChina</td>
            </tr>

            <tr>
              <td>Colombia</td>
              <td>Comoros</td>
              <td>Congo</td>
              <td>Congo, The Demoratic Republic of the</td>
              <td>Costa Rica</td>
            </tr>
            <tr>
              <td>Cote D'lvoire</td>
              <td>Croatia</td>
              <td>Cuba</td>
              <td>Czech Republic</td>
              <td>Denmark</td>
            </tr>

            <tr>
              <td>Djibouti</td>
              <td>Dominica</td>
              <td>Dominican Republic</td>
              <td>Ecuador</td>
              <td>Egypt</td>
            </tr>

            <tr>
              <td>El Salvador</td>
              <td>Equatorial Guinea</td>
              <td>Eritrea</td>
              <td>Estonia</td>
              <td>Ethiopia</td>
            </tr>
            <tr>
              <td>Fiji</td>
              <td>Finland</td>
              <td>France</td>
              <td>Gabon</td>
              <td>Gambia</td>
            </tr>

            <tr>
              <td>Georgia</td>
              <td>Germany</td>
              <td>Ghana</td>
              <td>Greece</td>
              <td>Grenada</td>
            </tr>
            <tr>
              <td>Guatemala</td>
              <td>Guinea</td>
              <td>Guinea-Bissau</td>
              <td>Guyana</td>
              <td>Haiti</td>
            </tr>

            <tr>
              <td>Honduras</td>
              <td>Hong Kong, China</td>
              <td>Hungary</td>
              <td>Iceland</td>
              <td>India</td>
            </tr>

            <tr>
              <td>Indonesia</td>
              <td>Iran, Islamic Republic of</td>
              <td>Iraq</td>
              <td>Ireland</td>
              <td>Israel</td>
            </tr>

            <tr>
              <td>Italy</td>
              <td>Jamaica</td>
              <td>Japan</td>
              <td>Jordan</td>
              <td>Kazakhstan</td>
            </tr>

            <tr>
              <td>Kenya</td>
              <td>Kiribati</td>
              <td>Korea North</td>
              <td>Korea South</td>
              <td>Kuwait</td>
            </tr>

            <tr>
              <td>Kyrgyzstan</td>
              <td>Lao People's Democratic Republic</td>
              <td>Latvia</td>
              <td>Lebanon</td>
              <td>Lesotho</td>
            </tr>

            <tr>
              <td>Liberia</td>
              <td>Libya</td>
              <td>Liechtenstein</td>
              <td>Lithuania</td>
              <td>Luxembourg</td>
            </tr>

            <tr>
              <td>Macau</td>
              <td>Macedonia</td>
              <td>Madagascar</td>
              <td>Malawi</td>
              <td>Malaysia</td>
            </tr>

            <tr>
              <td>Maldives</td>
              <td>Mali</td>
              <td>Malta</td>
              <td>Marshall Islands</td>
              <td>Mauritania</td>
            </tr>
            <tr>
              <td>Mauritius</td>
              <td>Mexico</td>
              <td>Micronesia, Federated States of</td>
              <td>Moldova,Republic of</td>
              <td>Monaco</td>
            </tr>

            <tr>
              <td>Mongolia</td>
              <td>Montenegro</td>
              <td>Morocco</td>
              <td>Mozambique</td>
              <td>Myanmar</td>
            </tr>
            <tr>
              <td>Namibia</td>
              <td>Nauru</td>
              <td>Nepal</td>
              <td>Netherlands</td>
              <td>New zealand</td>
            </tr>

            <tr>
              <td>Nicaragua</td>
              <td>Niger</td>
              <td>Nigeria</td>
              <td>Norway</td>
              <td>Oman</td>
            </tr>

            <tr>
              <td>Pakistan</td>
              <td>Palau</td>
              <td>Panama</td>
              <td>Papua new Guinea</td>
              <td>Paraguay</td>
            </tr>

            <tr>
              <td>Peru</td>
              <td>Philippines</td>
              <td>Poland</td>
              <td>Portugal</td>
              <td>Qatar</td>
            </tr>

            <tr>
              <td>Republic of Cyprus</td>
              <td>Romania</td>
              <td>Russian Federation</td>
              <td>Rwanda</td>
              <td>Saint kitts and Newis</td>
            </tr>

            <tr>
              <td>Saint Lucia</td>
              <td>Saint Vincent and the Grenadines</td>
              <td>Samoa</td>
              <td>San Marino</td>
              <td>Sao Tome and Principe</td>
            </tr>

            <tr>
              <td>Saudi Arabia </td>
              <td>Senegal</td>
              <td>Serbia</td>
              <td>Seychelles</td>
              <td>Sierra Leone</td>
            </tr>

            <tr>
              <td>Singapore</td>
              <td>Slovakia</td>
              <td>Solomon Islands</td>
              <td>Somalia</td>
              <td>South Africa</td>
            </tr>

            <tr>
              <td>Spain</td>
              <td>Sri Lanka</td>
              <td>Sudan</td>
              <td>Suriname</td>
              <td>Swaziland</td>
            </tr>

            <tr>
              <td>Sweden</td>
              <td>Switzerland</td>
              <td>Syrian Arab Republic</td>
              <td>Taiwan, China</td>
              <td>Tajikistan</td>
            </tr>

            <tr>
              <td>tanzania, United Republic of </td>
              <td>Thailand</td>
              <td>Timor-Leste</td>
              <td>Togo</td>
              <td>Tonga</td>
            </tr>

            <tr>
              <td>Trinidad and Tobago</td>
              <td>Tunisia</td>
              <td>Turkey</td>
              <td>Turkmenistan</td>
              <td>Tuvalu</td>
            </tr>

            <tr>
              <td>Uganda </td>
              <td>Ukraine</td>
              <td>Unites Arab Emirates</td>
              <td>United Kingdom</td>
              <td>United states</td>
            </tr>

            <tr>
              <td>Uruguay </td>
              <td>Uzbekistan</td>
              <td>Vanuatu</td>
              <td>Vatican City</td>
              <td>Venezuela</td>
            </tr>

            <tr>
              <td>Vietnam</td>
              <td>Yemen</td>
              <td>Zambia</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      <FooterDiv>
        <img src={cardlogo} alt="card" />
        <StyledList>
          <h5>Quick Links</h5>
          <ul>
            <li>
              <BiChevronRight />
              <Link to="#">Home</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Apply Now</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Additional Benefits</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Contact Us</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Fees</Link>
            </li>
          </ul>
        </StyledList>
        <div>
          <h5>Important Links</h5>
          <ul>
            <li>
              <BiChevronRight />
              <Link to="#">FAQ</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Terms & Conditions</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Privacy Policy</Link>
            </li>
            <li>
              <BiChevronRight />
              <Link to="#">Refund Policy</Link>
            </li>
          </ul>
        </div>
      </FooterDiv>
      <HomeFooter>
        <p>
          We use Cookies to ensure you get the best experience on our website.
          If you continue browsing, you accept our cookies.
          <Link to="/privacy-policy">Learn More</Link>
          <button>close</button>
        </p>
      </HomeFooter>
    </StyledArrival>
  );
};

export default Home;
