import React from "react";
import { StyledDiv, StyledWrapper } from "./style";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <StyledWrapper>
      <h2>TERMS AND CONDITIONS</h2>
      <StyledDiv>
        <h5>Background</h5>
        <p>
          For inquiries relating to your SG Arrival Card application: in the
          first instance please send us an email to:
          <Link to="#">inquiries@sg-arrivalcard.online</Link>
        </p>
        <h6>Our Trading Address:</h6>
        <ul>
          <li>sg-arrivalcard.online, </li>
          <li>1800 Pembrook Dr. </li>
          <li>Suite 300, Orlando </li>
          <li>FL 32810 </li>
        </ul>
        <h5>TERMS AND CONDITIONS</h5>
        <p>
          This agreement applies as between you, the User of this Web Site and
          sg-arrivalcard.online, the owner(s) of this Web Site. Your agreement
          to comply with and be bound by these terms and conditions is deemed to
          occur upon your first use of the Web Site. If you do not agree to be
          bound by these terms and conditions, you should stop using the Web
          Site immediately. www.sg-arrivalcard.online is a trading name of
          sg-arrivalcard.online is a company registered in United States. The
          registered office of sg-arrivalcard.online, 1800 Pembrook Dr. Suite
          300, Orlando, FL 32810
        </p>
        <h5>1. DEFINITIONS AND INTERPRSG ARRIVAL CARDTION</h5>
        <p>
          In this Agreement the following terms shall have the following
          meanings: “Content” means any text, graphics, images, audio, video,
          software, data compilations and any other form of information capable
          of being stored in a computer that appears on or forms part of this
          Web Site; “Premises” means , sg-arrivalcard.online, 1800 Pembrook Dr.
          Suite 300, Orlando, FL 32810 “Service” means collectively any online
          facilities, tools, services or information that sg-arrivalcard.online
          makes available through the Web Site either now or in the future;
          “System” means any online communications infrastructure that
          sg-arrivalcard.online makes available through the Web Site either now
          or in the future. This includes, but is not limited to, web-based
          email, message boards, live chat facilities and email links; “User” /
          “Users” means any third party that accesses the Web Site and is not
          employed by sg-arrivalcard.online and acting in the course of their
          employment; and “Web Site” means the website that you are currently
          using (www.sg-arrivalcard.online) and any sub-domains of this site
          (e.g. subdomain.sg-arrivalcard.online ) unless expressly excluded by
          their own terms and conditions.
        </p>

        <h5> 2. INTELLECTUAL PROPERTY </h5>
        <p>
          {" "}
          2.1 All Content included on the Web Site, unless uploaded by Users,
          including, but not limited to, text, graphics, logos, icons, images,
          sound clips, video clips, data compilations, page layout, underlying
          code and software is the property of www.sg-arrivalcard.online, our
          affiliates or other relevant third parties. By continuing to use the
          Web Site you acknowledge that such material is protected by applicable
          US and International intellectual property and other relevant laws.
        </p>
        <p>
          2.2 Subject to sub-clause 2.3 you may not reproduce, copy, distribute,
          store or in any other fashion re-use material from the Web Site unless
          otherwise indicated on the Web Site or unless given express written
          permission to do so by sg-arrivalcard.online
        </p>
        <p>
          2.3 Material from the Web Site may be re-used without written
          permission where any of the exceptions in line with United States of
          America law
        </p>

        <h5>3. LINKS TO OTHER WEB SITES </h5>
        <p>
          {" "}
          This Web Site may contain links to other sites. Unless expressly
          stated, these sites are not under the control of sg-arrivalcard.online
          or that of our affiliates. We assume no responsibility for the content
          of such web sites and disclaim liability for any and all forms of loss
          or damage arising out of the use of them. The inclusion of a link to
          another site on this Web Site does not imply any endorsement of the
          sites themselves or of those in control of them.
        </p>

        <h5>4. LINKS TO THIS WEB SITE</h5>
        <p>
          Those wishing to place a link to this Web Site on other sites may do
          so only to the home page of the site www.sg-arrivalcard.online without
          prior permission. Deep linking (i.e. links to specific pages within
          the site) requires the express permission of sg-arrivalcard.online. To
          find out more please contact us by email at
          <Link to="#"> inquiries@sg-arrivalcard.online</Link>
        </p>

        <h5>5. PRIVACY</h5>
        <p>
          Use of the Web Site is also governed by our Privacy Policy which is
          incorporated into these terms and conditions by this reference. To
          view the Privacy Policy, please click on the link above.
        </p>

        <h5>6. DISCLAIMERS</h5>
        <p>
          6.1 sg-arrivalcard.online makes no warranty or representation that the
          Web Site will meet your requirements, that it will be of satisfactory
          quality, that it will be for a particular purpose, that it will not
          infringe the rights of third parties, that it will be compatible with
          all systems, that it will be secure and that all information provided
          will be accurate. We make no guarantee of any specific results from
          the use of our Service.
        </p>

        <p>
          6.2 No part of this Web Site is intended to constitute advice and the
          Content of this Web Site should not be relied upon when making any
          decisions or taking any action of any kind.
        </p>

        <h5>7. AVAILABILITY OF THE WEB SITE</h5>
        <p>
          The Service is provided “as is” and on an “as available” basis. We
          give no warranty that the Service will be free of defects and / or
          faults. To the maximum extent permitted by The Law of United States of
          America, we provide no warranties (express or implied) of fitness for
          a particular purpose, accuracy of information, compatibility and
          satisfactory quality. www.sg-arrivalcard.online accepts no liability
          for any disruption or non-availability of the Web Site resulting from
          external causes including, but not limited to, ISP equipment failure,
          host equipment failure, communications network failure, power failure,
          natural events, acts of war or legal restrictions and censorship.
        </p>

        <h5> 8. LIMITATION OF LIABILITY</h5>
        <p>
          8.1 To the maximum extent permitted by United States of America law,
          sg-arrivalcard.online accepts no liability for any direct or indirect
          loss or damage, foreseeable or otherwise, including any indirect,
          consequential, special or exemplary damages arising from the use of
          the Web Site or any information contained therein. Users should be
          aware that they use the Web Site and its Content at their own risk.
        </p>
        <p>
          8.2 Nothing in these terms and conditions excludes or restricts
          sg-arrivalcard.online liability for death or personal injury resulting
          from any negligence or fraud on the part of sg-arrivalcard.online.
        </p>
        <p>
          8.3 Every effort has been made to ensure that these terms and
          conditions adhere strictly with the relevant provisions of the all
          applicable ISA law. However, in the event that any of these terms are
          found to be unlawful, invalid or otherwise unenforceable, that term is
          to be deemed severed from these terms and conditions and shall not
          affect the validity and enforceability of the remaining terms and
          conditions. This term shall apply only within jurisdictions where a
          particular term is illegal.
        </p>

        <h5>9. NO WAIVER</h5>
        <p>
          In the event that any party to these Terms and Conditions fails to
          exercise any right or remedy contained herein, this shall not be
          construed as a waiver of that right or remedy.
        </p>

        <h5>10. PREVIOUS TERMS AND CONDITIONS</h5>
        <p>
          In the event of any conflict between these Terms and Conditions and
          any prior versions thereof, the provisions of these Terms and
          Conditions shall prevail unless it is expressly stated otherwise.
        </p>

        <h5>11. NOTICES</h5>
        <p>
          All notices / communications shall be given to us either by post to
          our Premises (see address above) or by email to
          inquiries@sg-arrivalcard.online Such notice will be deemed received 3
          days after posting if sent by first class post, the day of sending if
          the email is received in full on a business day and on the next
          business day if the email is sent on a weekend or public holiday.
        </p>

        <h5>12. LAW AND JURISDICTION</h5>
        <p>
          These terms and conditions and the relationship between you and
          sg-arrivalcard.online shall be governed by and construed in accordance
          with the Law of United States of America and sg-arrivalcard.online and
          you agree to submit to the exclusive jurisdiction of the Courts of
          United States of America.
        </p>

        <h5>13. TIME FRAME</h5>
        <p>
          We aim to process successfully submitted applications within 72 hours.
          However we are not liable or responsible for delays caused in
          processing of the application. We do not advise submitting an
          application for a SG Arrival Card Application via
          www.sg-arrivalcard.online within 72 hours of travel.
        </p>

        <h5>14. SERVICE COSTS AND FEES</h5>
        <p>
          By accepting these terms and conditions and after successful payment,
          you are instructing sg-arrivalcard.online data processing team to
          process your SG Arrival Card application on your behalf. The $60.00 -
          $79.99 USD fee is charged per applicant and includes the following
          benefits; Confirmation Of Application, Admission and Error Checks On
          Application, Reimbursement For Declined SG Arrival Card Application,
          24/7 Email Support and Advice. We as a merchant shall be under no
          liability whatsoever in respect of any loss or damage arising directly
          or indirectly out of the decline of authorization for any Transaction,
          on Account of the Cardholder having exceeded the preset limit mutually
          agreed by us with our acquiring bank from time to time.
        </p>

        <h5>15. REFUND & COMPLAINTS</h5>
        <p>
          Users of our services are protected by United States of America &
          International law. The service we offer is a digital personalized
          service, by proceeding to submit an application you agree to waive
          your cancellation rights.
        </p>

        <h5>16. DISCLAIMER</h5>
        <p>
          This Website contains information concerning sg-arrivalcard.online,
          Its Services, Products, Materials and other information that may be
          for our customers, employees, shareholders and other stake holders and
          the general public. The said information is provided on an “as is”
          basis without any warranty of any kind. sg-arrivalcard.online
          disclaims all representations and warranties, express or implied, as
          to the accuracy, currency, or completeness of the contents of this
          website and use of its contents for any particular purpose, to the
          maximum extent permitted by applicable law. sg-arrivalcard.online
          shall not be liable for any kind of direct, indirect, consequential,
          Incidental, special or punitive losses or damages (even if
          sg-arrivalcard.online has been advised of the possibility of such
          damages) including any damage to your computer or any other device
          arising out of access to, use of or in ability to use this website, to
          the fullest extent as permitted by law. sg-arrivalcard.online does not
          warranty use of the contents of this website for any particular
          purpose. sg-arrivalcard.online shall not be liable
          sg-arrivalcard.online shall not be liable for any kind of direct,
          indirect, consequential, Incidental, special or punitive losses or
          damage arising out of access to, use of or in ability to use this
          website. You should carefully read all information provided before
          using any product purchased on this website. Any links on this website
          to websites operated by third parties are provided for your
          convenience only. sg-arrivalcard.online is not responsible for the
          content and performance of these websites or for your transactions
          with them and our inclusion of links to such websites does not imply
          any endorsement of the material on such sites.
        </p>

        <h5>REFUNDS & COMPLAINTS PROCEDURE</h5>
        <p>
          All refund requests and formal complaints must be made via email to
         <Link to="#">inquiries@sg-arrivalcard.online</Link>
        </p>
      </StyledDiv>
    </StyledWrapper>
  );
};

export default TermsAndConditions;
