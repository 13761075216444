import React, { useEffect } from "react";
import {
  MiniLeftBar,
  Menu,
  UserInfo,
  StyledLogo,
  Aside,
  OverlayWrapper,
} from "./style";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AdminLogo from "../../../../assests/img/logo.png";
import Logout from "../../../../assests/icons/logout.png";
import AwaitingIcon from "../../../../assests/icons/awaiting_response.png";
import OrderIcon from "../../../../assests/icons/order_history.png";
import DeletedIcon from "../../../../assests/icons/delete_orders.png";
import RefundOrdersIcon from "../../../../assests/images/refund_order.svg";
import ZohoIcon from "../../../../assests/images/zoho.svg";
import ChangePassword from "../../../../assests/images/password.svg";
import GatewayIcon from "../../../../assests/icons/gateway_setting.png";
import ManageTeam from "../../../../assests/images/manage_team.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/authSlice";
import { FaBars } from "react-icons/fa";
import { getMailCount, toggleSidebar } from "../../../../redux/orderSlice";
import jwt from "jwt-decode";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const count = useSelector((state) => state?.order?.count);
  const showSideBar = useSelector((state) => state?.order?.showSideBar);
  const mailCount = useSelector((state) => state?.order?.mailCount);
  const token = localStorage.getItem("user");

  let userDetails;

  if (token !== null) {
    const decodedToken = jwt(token);
    userDetails = decodedToken?.user;
  }

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    dispatch(logout());
  };

  useEffect(() => {
    if (token) {
      dispatch(getMailCount());
    }
  }, [dispatch, token]);

  return (
    <>
      <OverlayWrapper overlay={showSideBar ? true : false}></OverlayWrapper>
      <MiniLeftBar>
        <ul className="menu_list">
          <li>
            <Link
              to="#"
              className="bars"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <FaBars className="bars-icon" />
            </Link>
          </li>
        </ul>
      </MiniLeftBar>
      <Aside>
        <div className={showSideBar ? "sidebar show" : "sidebar hide"}>
          <div>
            <Menu>
              <ul>
                <li>
                  <UserInfo>
                    <div className="image">
                      <img
                        id="profile_pic"
                        src={AdminLogo}
                        alt="admin"
                        onClick={() => navigate("/admin")}
                      />
                    </div>
                    <StyledLogo>
                      <h6>{userDetails?.name}</h6>
                      <NavLink to="#" onClick={handleLogout}>
                        <img
                          src={Logout}
                          className="sidebar_icons"
                          alt="logout"
                        />
                        Logout
                      </NavLink>
                    </StyledLogo>
                  </UserInfo>
                </li>
                <li>
                  <NavLink to="/admin/awaiting-order" className="menu-toggle">
                    <img src={AwaitingIcon} alt="AwaitingIcon" />
                    <span id="awaitingorder">Awaiting Response</span>{" "}
                    <span className="span-class">
                      {count?.awaitingOrdersCount}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/order-history" className="menu-toggle">
                    <img src={OrderIcon} alt="OrderIcon" />
                    <span id="allorder">Order History</span>{" "}
                    <span className="span-class">{count?.allOrdersCount}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/delete-order" className="menu-toggle">
                    <img src={DeletedIcon} alt="DeletedIcon" />
                    <span id="deletedorder">Deleted Orders</span>{" "}
                    <span className="span-class">
                      {count?.deletedOrdersCount}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/refund-order" className="menu-toggle">
                    <img src={RefundOrdersIcon} alt="RefundOrdersIcon" />
                    <span id="refundorder">Refund Orders</span>{" "}
                    <span className="span-class">
                      {count?.refundOrdersCount}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/admin/incomplete-payment-order"
                    className="menu-toggle"
                  >
                    <img src={AwaitingIcon} alt="Incompleted Payments" />
                    <span id="refundorder">Incompleted Payments</span>{" "}
                    <span className="span-class">
                      {count?.incompletedpaymentOrdersCount}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/admin/incomplete-order" className="menu-toggle">
                    <img src={AwaitingIcon} alt="IncompletedOrdersIcon" />
                    <span id="refundorder">Incompleted Orders</span>{" "}
                    <span className="span-class">
                      {count?.incompletedOrdersCount}
                    </span>
                  </NavLink>
                </li>
                <li className="header">--Reporting</li>

                <li>
                  <NavLink to="#" className="menu-toggle">
                    <img src={ZohoIcon} alt="ZohoIcon" />
                    <span>Zoho</span>{" "}
                    <span className="span-class">
                      {mailCount ? mailCount : 0}
                    </span>
                  </NavLink>
                </li>
                {userDetails?.type === "Admin" && (
                  <>
                    <li className="header">--Settings</li>

                    <li className="sm_menu_btm ">
                      {" "}
                      <NavLink
                        to="/admin/change-password"
                        className="menu-toggle"
                      >
                        <img src={ChangePassword} alt="ChangePassword" />
                        <span>Change Password</span>
                      </NavLink>
                    </li>
                    <li className="sm_menu_btm ">
                      {" "}
                      <NavLink to="/admin/setting" className="menu-toggle">
                        <img src={GatewayIcon} alt="GatewayIcon" />
                        <span>Gateway</span>
                      </NavLink>
                    </li>
                    <li className="sm_menu_btm ">
                      {" "}
                      <NavLink to="/admin/manage-team" className="menu-toggle">
                        <img src={ManageTeam} alt="ManageTeam" />
                        <span>Manage Team</span>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </Menu>
          </div>
        </div>
      </Aside>
    </>
  );
};

export default AdminSidebar;
