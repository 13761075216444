import React, { useEffect } from "react";
import { MainContainer } from "./style";
import ReactDataTable from "../../../../Components/ReactDataTable/index";
import { getOrdersData } from "../../../../redux/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CompletedOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CompletedOrderData = useSelector((state) => state?.order?.OrdersData);
  useEffect(() => {
    dispatch(getOrdersData("completedorder"))
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.data?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/auth/login");
        }
      });
    document.title = "Completed Orders";
  }, [dispatch, navigate]);

  const tableData = CompletedOrderData;

  return (
    <MainContainer>
      <div className="card">
        <div className="header">
          <h2>
            <strong>Completed Orders</strong>
          </h2>
        </div>
        <div className="body">
          <form>
            <input
              type="hidden"
              id="order_id"
              className="order_id"
              name="order_id"
              value=""
            />

            <ReactDataTable data={tableData} />
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

export default CompletedOrders;
