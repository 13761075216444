// import React from "react";
// import Header from './Header';
import Main from './Main';
import Header from './Header/index';
import Footer from './Footer/Footer';
import Navbar from "./Navbar/index";

const DefaultLayout = (props) => (
  <div>
    <Header {...props} />
    <Navbar/>
    <Main {...props} />
    <Footer {...props} />
  </div>
);

export default DefaultLayout;
