import styled from "styled-components";

export const StyledNav = styled.nav`
  background: #fff;
  padding-bottom: 2rem;
  // padding-left: 20rem;

  & img {
    width: 13rem;
    height: 3rem;

    @media (max-width: 295px) {
      width: 10rem;
    }

    @media (max-width: 230px) {
      width: 9rem;
    }
    @media (max-width: 215px) {
      width: 8rem;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  li {
    // padding: 20px 25px;
  }

  @media (max-width: 535px) {
    padding-left: 10rem;
  }

  @media (max-width: 230px) {
    padding-left: 4rem;
  }
`;
