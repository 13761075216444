import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import manageTeamService from "../services/manageTeamService";

export const getTeamMemberList = createAsyncThunk(
  "/admin/team-member-list",
  async () => {
    try {
      const response = await manageTeamService.getTeamMemberList();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTeamMemberById = createAsyncThunk(
  "admin/getTeamMemberById",
  async (teamMemberId) => {
    try {
      const response = await manageTeamService.getTeamMemberById(teamMemberId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "/admin/delete-team-member",
  async (data) => {
    try {
      await manageTeamService.deleteTeamMember(data);
      return data.teamMemberId;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const teamMemberRegistration = createAsyncThunk(
  "/admin/team-member-registration",
  async (formData) => {
    try {
      const response = await manageTeamService.teamMemberRegistration(formData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateTeamMember = createAsyncThunk(
  "/admin/update-team-member",
  async (updateData) => {
    try {
      const response = await manageTeamService.updateTeamMember(updateData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const manageTeamSlice = createSlice({
  name: "manageTeam",
  initialState: {
    loading: false,
    error: null,
    teamMembers: [],
    teamMember: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getTeamMemberList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamMemberList.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMembers = action?.payload?.data;
    });
    builder.addCase(getTeamMemberList.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(getTeamMemberById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamMemberById.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMember = action?.payload?.data;
    });
    builder.addCase(getTeamMemberById.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(teamMemberRegistration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(teamMemberRegistration.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMembers = [...state.teamMembers, action?.payload?.data];
    });
    builder.addCase(teamMemberRegistration.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMembers = [
        ...state.teamMembers.filter((x) => x.id !== action?.payload?.data?.id),
        action?.payload?.data,
      ];
      state.teamMember = action?.payload?.data;
    });
    builder.addCase(updateTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMembers = state.teamMembers.filter(
        (item) => item.id !== action.payload[0]
      );
    });
    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default manageTeamSlice.reducer;
