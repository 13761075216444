import styled from "styled-components";

export const StyledFooter = styled.div`
  // background: #343a40;
  display: flex;
  align-items: center;
  background: #161b1f;
    padding: 10px 0;
  width: 100%;


  & p {
    color: #fff;
    padding-left: 40rem;
    align-items: center!important;

    @media (max-width: 827px) {
      padding-left: 3rem;
    }
  
  @media (max-width: 310px) {
    padding-left: 1.5rem;
  }

  @media (max-width: 230px) {
    padding-left: 0.5rem;
  }
}



 
`;
