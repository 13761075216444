import React from "react";
import {
  StyledContainer,
  StyledChild,
  CardContainer,
  StyledBenefit,
} from "./style";
import OrderIcon from "../../assests/img/order_icon.png";
import ReadingIcon from "../../assests/img/reading_icon.png";
import MobileIcon from "../../assests/img/mobile.png";
import ChatIcon from "../../assests/img/chat.png";
import HelpLineIcon from "../../assests/img/helpline_icon.png";
import SecurityIcon from "../../assests/img/security_icon.png";
import PhoneIcon from "../../assests/img/phone.png";
import SupportIcon from "../../assests/img/support_icon.png";
import TranslationIcon from "../../assests/img/translation_travel_icon.png";

const AdditionalBenefits = () => {
  return (
    <StyledContainer>
      <h2>Additional Benefits</h2>
      <StyledChild>
        <CardContainer>
          <StyledBenefit>
            <div>
              <img src={OrderIcon} alt="img1" />
              <p>All SG Arrival Card applications are manually checked</p>
            </div>
            <div>
              <img src={ReadingIcon} alt="img1" />
              <p>Instant email confirmation</p>
            </div>
            <div>
              <img src={MobileIcon} alt="img1" />
              <p>Instant SMS confirmation</p>
            </div>
          </StyledBenefit>
          <StyledBenefit>
            <div>
              <img src={ChatIcon} alt="img1" />
              <p>live chat support</p>
            </div>
            <div>
              <img src={HelpLineIcon} alt="img1" />
              <p>Customer support helpline</p>
            </div>
            <div>
              <img src={SecurityIcon} alt="img1" />
              <p>All data is fully secure and not passed to any third party.</p>
            </div>
          </StyledBenefit>
          <StyledBenefit>
            <div>
              <img src={PhoneIcon} alt="img1" />
              <p>Our website is mobile and tablet friendly.</p>
            </div>
            <div>
              <img src={SupportIcon} alt="img1" />
              <p>Telephone application service.</p>
            </div>
            <div>
              <img src={TranslationIcon} alt="img1" />
              <p>Our Website can be changed to any Language.</p>
            </div>
          </StyledBenefit>{" "}
        </CardContainer>
      </StyledChild>
    </StyledContainer>
  );
};

export default AdditionalBenefits;
