import React, { useState } from "react";
import {
  Divwrapper,
  MainDiv,
  FirstDiv,
  Savebutton,
  StyledContainer,
} from "./style";
import { FaInfoCircle, FaPassport } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { BiUser } from "react-icons/bi";

const ApplyNow = () => {
  const [checked, setChecked] = useState(true);
  const [show, setShow] = useState();
  const [travelMode, setTravelMode] = useState();
  const [accMode, setAccMode] = useState();

  const handleModeChange = (e) => {
    setTravelMode(e.target.value);
  };

  const handleChange = (e) => {
    setShow(e.target.value);
  };

  const handleAccommodation = (e) => {
    setAccMode(e.target.value);
  };
  return (
    <Divwrapper>
      <StyledContainer>
        <form>
          <div>
            <h5>Personal Information</h5>
          </div>
          <div>
            <MainDiv>
              <FirstDiv>
                <label htmlFor="fname">
                  Full Name (In Passport) <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="fname"
                  name="first_name"
                  placeholder="Full Name"
                />
                <span className="user_icon">
                  <BiUser />
                </span>
              </FirstDiv>
              <br />

              <FirstDiv>
                <label htmlFor="lname">
                  Passport Number <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="lname"
                  name="last_name"
                  placeholder="Passport Number"
                />
                <span className="user_icon">
                  <FaPassport />
                </span>
              </FirstDiv>
            </MainDiv>
            <MainDiv>
              <FirstDiv>
                <label htmlFor="email">
                  Date of Birth <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="DD/MM/YYYY"
                />
              </FirstDiv>

              <br />
              <FirstDiv>
                <label htmlFor="contact">
                  Nationality/Citizenship <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name" id="role">
                  <option value="role"></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </FirstDiv>
            </MainDiv>
            <MainDiv>
              <FirstDiv>
                <label htmlFor="email">
                  Sex <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name" id="role">
                  <option value="role">--Please Select Option--</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </FirstDiv>

              <br />
              <FirstDiv>
                <label htmlFor="contact">
                  Date of Passport Expiry <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="number"
                  id="contact"
                  name="contact_no"
                  placeholder=""
                />
              </FirstDiv>
            </MainDiv>
            <MainDiv>
              <FirstDiv>
                <label htmlFor="email">
                  Place of Residence <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name" id="role">
                  <option value="role"></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <br />
              </FirstDiv>
              <FirstDiv>
                <label htmlFor="email">
                  Country /Place of Birth <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name" id="role">
                  <option value="role"></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <br />
              </FirstDiv>
            </MainDiv>
            <MainDiv>
              <FirstDiv>
                <label htmlFor="email">
                  Date of Arrival <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="DD/MM/YYYY"
                />
              </FirstDiv>

              <br />
              <FirstDiv>
                <label htmlFor="contact">
                  Date of Departure <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="number"
                  id="contact"
                  name="contact_no"
                  placeholder="DD/MM/YYYY"
                />
                <br />
              </FirstDiv>
            </MainDiv>
          </div>
          <br />
          <br />
          <h5>Email Address</h5>
          <p>
            If you are eligible for entry into Singapore, you will receive an
            electronic visit pass (in lieu of inked endorsement stamps on the
            passport) sent to you via this email address. Please therefore
            ensure that you provide a valid email address..
          </p>
          <br />
          <MainDiv>
            <FirstDiv>
              <label htmlFor="fname">
                Email <span className="asterisk">*</span>
              </label>
              <br />
              <input
                type="text"
                id="fname"
                name="first_name"
                placeholder="Emri i plote"
              />
            </FirstDiv>

            <br />
            <FirstDiv>
              <label htmlFor="lname">
                Confirm Email Address <span className="asterisk">*</span>
              </label>
              <br />
              <input
                type="text"
                id="lname"
                name="last_name"
                placeholder="Enter Your Last Name"
              />

              <br />
            </FirstDiv>
          </MainDiv>
          <MainDiv>
            <FirstDiv>
              <label htmlFor="fname">
                Country/Region Code <span className="asterisk">*</span>
              </label>
              <br />

              <select name="role_name" id="role">
                <option value="role"></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </FirstDiv>

            <br />
            <FirstDiv>
              <label htmlFor="lname">
                Mobile Number <span className="asterisk">*</span>
              </label>
              <br />
              <input
                type="text"
                id="lname"
                name="last_name"
                placeholder="Enter Your Last Name"
              />
              <br />
            </FirstDiv>
          </MainDiv>
          <br />

          <h5>Trip Information</h5>
          <h2>
            Date of Arrival to Singapore <span className="asterisk">*</span>
          </h2>
          <br />
          <label htmlFor="password">
            Mode Of Travel <span className="asterisk">*</span>
          </label>
          <br />
          <select name="role_name" id="role" onChange={handleChange}>
            <option value="">--Please Select Option--</option>
            <option value="air">Air</option>
            <option value="land">Land</option>
            <option value="sea">Sea</option>
          </select>
          <br />
          {
            //IF AIR IS SELECTED
            show === "air" ? (
              <>
                <label htmlFor="password">
                  Mode Of Travel(Details) <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name" onChange={handleModeChange}>
                  <option value="">--Please Select Option--</option>
                  <option value="commercial">COMMERCIAL</option>
                  <option value="cargoairline">
                    PRIVAT/CARGO AIRLINE/OTHERS
                  </option>
                </select>
              </>
            ) : show === "land" ? (
              <>
                <label htmlFor="password">
                  Mode Of Travel(LAND Details){" "}
                  <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name">
                  <option value="role">--Please Select Option--</option>
                  <option value="air">BUS</option>
                  <option value="land">CAR</option>
                  <option value="land">LORRY</option>
                  <option value="land">MOTORCYCLE</option>
                  <option value="land">VAN</option>
                  <option value="land">RAIL</option>
                </select>
                <label htmlFor="vehicle">
                  Vehicle Number <span className="asterisk">*</span>
                </label>
                <br />
                <input type="text" id="vehicle" name="vehicle" placeholder="" />
              </>
            ) : show === "sea" ? (
              <>
                <label htmlFor="password">
                  Mode Of Travel(SEA Details){" "}
                  <span className="asterisk">*</span>
                </label>
                <br />
                <select name="role_name">
                  <option value="role">--Please Select Option--</option>
                  <option value="air">CRUISE</option>
                  <option value="land">COMMERCIAL VESSEL</option>
                  <option value="land">LORRY</option>
                  <option value="land">FERRY</option>
                  <option value="land">PRIVATE CRAFT</option>
                </select>
                <label htmlFor="cruise_vessel">
                  Cruise / Vessel Name <span className="asterisk">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="cruise_vessel"
                  name="cruise_vessel"
                  placeholder=""
                />
              </>
            ) : null
          }
          {show === "air" && travelMode === "commercial" ? (
            <>
              <MainDiv>
                <FirstDiv>
                  <label htmlFor="password">
                    Flight Code <span className="asterisk">*</span>
                  </label>
                  <br />
                  <select name="role_name" id="role">
                    <option value="role"></option>
                  </select>
                </FirstDiv>
                <br />
                <FirstDiv>
                  <label htmlFor="lname">
                    Flight Number <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    id="lname"
                    name="last_name"
                    placeholder=""
                  />
                </FirstDiv>
              </MainDiv>
            </>
          ) : show === "air" && travelMode === "cargoairline" ? (
            <>
              {" "}
              <label htmlFor="lname">
                Carrier Name/Flight Number <span className="asterisk">*</span>
              </label>
              <br />
              <input type="text" id="lname" name="last_name" placeholder="" />
            </>
          ) : null}
          <br />
          <h2>
            Type of Accommodation in Singapore{" "}
            <span className="asterisk">*</span>
            <FaInfoCircle />
          </h2>
          <p>
            If you are serving a Stay-Home Notice (SHN), please declare the
            address where you will be serving the SHN.
          </p>

          <label htmlFor="password">
            Type of Accommodation <span className="asterisk">*</span>
          </label>
          <br />
          <select name="role_name" id="role" onChange={handleAccommodation}>
            <option value="role">--Please Select Option--</option>
            <option value="hotels">Hotels</option>
            <option value="residentials">Residentials</option>
            <option value="others">Others</option>
          </select>
          <br />
          {accMode === "hotels" ? (
            <>
              <label htmlFor="hotel_name">
                Name of Hotel
                <span className="asterisk">*</span>
              </label>
              <p>
                If you are unable to find your hotel name, select "OTHERS", and
                key in the hotel name manually.{" "}
              </p>
              <br />
              <select name="hotel_name" id="role">
                <option value=""></option>
                <option value="">api integrate</option>
              </select>
            </>
          ) : accMode === "others" ? (
            <>
              <label htmlFor="accomodation">
                Accommodation (Others) <span className="asterisk">*</span>
              </label>
              <br />
              <select name="accomodation" id="role">
                <option value="">--Please Select Option--</option>
                <option value="dayTrip">Day Trip</option>
                <option value="transit">Transit</option>
              </select>
            </>
          ) : accMode === "residentials" ? (
            <>
              <MainDiv>
                <FirstDiv>
                  <label htmlFor="postal_code">
                    Postal Code <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input
                    type="postal_code"
                    id="postal_code"
                    name="postal_code"
                  />
                </FirstDiv>

                <br />
                <FirstDiv>
                  <label htmlFor="block_number">
                    Block Number <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input type="text" id="block_number" name="block_number" />
                  <br />
                </FirstDiv>
              </MainDiv>

              <MainDiv>
                <FirstDiv>
                  <label htmlFor="street_name">
                    Street Name <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input type="text" id="street_name" name="street_name" />
                </FirstDiv>

                <br />
                <FirstDiv>
                  <label htmlFor="building_name">
                    Building Name <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input type="text" id="building_name" name="building_name" />
                </FirstDiv>

                <br />
              </MainDiv>

              <MainDiv>
                <FirstDiv>
                  <label htmlFor="email">
                    Floor Number <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Please Enter Information Or Select Not Applicable"
                  />
                  <br />
                  <input
                    type="checkbox"
                    defaultChecked={checked}
                    onChange={() => setChecked(!checked)}
                    id="next_city_of_disembarkation_checkbox"
                  />
                  <b> Not Applicable</b>
                </FirstDiv>
                <br />
                <FirstDiv>
                  <label htmlFor="contact">
                    Unit Number <span className="asterisk">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    id="unit"
                    name="unit_no"
                    placeholder="Please Enter Information Or Select Not Applicable"
                  />
                  <br />
                  <input
                    type="checkbox"
                    defaultChecked={checked}
                    onChange={() => setChecked(!checked)}
                    id="next_city_of_disembarkation_checkbox"
                  />
                  <b> Not Applicable</b>
                </FirstDiv>
              </MainDiv>
            </>
          ) : null}
          <label htmlFor="password">
            Last City / Port of Embarkation Before Singapore{" "}
            <span className="asterisk">*</span>
          </label>
          <br />
          <select name="role_name" id="role">
            <option value="role"></option>
            <option value=""></option>
          </select>
          <br />
          <MainDiv>
            <FirstDiv>
              <label htmlFor="password">
                Next City / Port of Disembarkation After Singapore{" "}
                <span className="asterisk">*</span>
                <FaInfoCircle />
              </label>
            </FirstDiv>
            <FirstDiv>
              <input
                type="checkbox"
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
                id="next_city_of_disembarkation_checkbox"
              />
              <b> Same as Last City</b>
            </FirstDiv>
          </MainDiv>
          <p>
            Holders of In-Principle Approval (IPA) letter who are applying for
            long-term pass may select "Same as Last City" if there is no
            information on the next city / port of disembarkation after
            Singapore during submission.
          </p>
          <select name="role_name" id="role">
            <option value="role"></option>
            <option value=""></option>
          </select>
          <br />

          <h5>Others</h5>
          <MainDiv>
            <FirstDiv>
              <p>
                Have you ever used a passport under different name to enter
                Singapore? <span className="asterisk">*</span>
              </p>
            </FirstDiv>
            <FirstDiv>
              <select name="role_name" id="role">
                <option value="role">--Please Select Option--</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </FirstDiv>
          </MainDiv>
          <br />
          <h5>Health Declaration</h5>
          <MainDiv>
            <FirstDiv>
              <p>
                Have you visited any of the listed countries in Africa or Latin
                America in the past 6 days prior to your arrival in Singapore?{" "}
                <span className="asterisk">*</span>
              </p>
            </FirstDiv>
            <FirstDiv>
              <select name="role_name" id="role">
                <option value="role">--Please Select Option--</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </FirstDiv>
          </MainDiv>

          <br />
          <MainDiv>
            <FirstDiv>
              <p>
                Did you start to have any symptoms in the last 7 days: fever,
                rash, cough, runny nose, sore throat, loss of sense of smell, or
                shortness of breath? <span className="asterisk">*</span>
              </p>
            </FirstDiv>
            <FirstDiv>
              <select name="role_name" id="role">
                <option value="role">--Please Select Option--</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </FirstDiv>
          </MainDiv>
          <br />
          <MainDiv>
            <FirstDiv>
              <p>
                Do you currently have fever, cough, shortness of breath,
                headache, or vomiting AND have visited Africa or the Middle East
                in the past 14 days prior to your arrival in Singapore?{" "}
                <span className="asterisk">*</span>
              </p>
            </FirstDiv>
            <FirstDiv>
              <select name="role_name" id="role">
                <option value="role">--Please Select Option--</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </FirstDiv>
          </MainDiv>
          <br />
          <MainDiv>
            <FirstDiv>
              <p>
                Are you fully vaccinated with WHO-EUL vaccines against COVID-19?{" "}
                <span className="asterisk">*</span>
              </p>
            </FirstDiv>
            <FirstDiv>
              <select name="role_name" id="role">
                <option value="role">--Please Select Option--</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </FirstDiv>
          </MainDiv>
          <br />
          <Savebutton type="submit">
            Submit & Pay
            <BsArrowRight />
          </Savebutton>
          <br />
        </form>
      </StyledContainer>
    </Divwrapper>
  );
};

export default ApplyNow;
