import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ChangeOrderStatus,
  getCount,
  getOrdersDetails,
} from "../../../redux/orderSlice";
import { addNewRemark } from "../../../redux/remarkSlice";
const initialValues = {
  remark: "",
  whatsapp: "",
  telephone: "",
  status_refund: "",
  voided: "",
  chargeback: "",
};

const remarkSchema = yup.object({
  remark: yup.string().required("Please enter your remark"),
  whatsapp: "",
  telephone: "",
  status_refund: "",
  voided: "",
  chargeback: "",
});

const AddComment = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const OrderDetails = useSelector((state) => state?.order?.OrderDetails);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: remarkSchema,
    onSubmit: (values) => {
      const remarkDataArr = {
        order_id: OrderDetails?.order_id,
        remark: values.remark,
        whatsapp: values.whatsapp,
        telephone: values.telephone,
        status_refund: values.status_refund,
        voided: values.voided,
        chargeback: values.chargeback,
      };
      dispatch(addNewRemark(remarkDataArr))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrdersDetails(orderId));
          }
        })
        .catch((err) => {
          if (err.status === 0) {
            toast.error(`${err.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  const handleOrderStatus = (e) => {
    const data = {
      orderId: [OrderDetails.order_id],
      permanentDeletedFlag: false,
      updatingStatus: e.target.id,
    };
    dispatch(ChangeOrderStatus(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getCount());
        }
      });
  };
  return (
    <div className="card-body">
      <div className="card-header">
        <h5>Add Comment Below</h5>
      </div>
      <div className="card-block">
        <div className="table-responsive" style={{ padding: "10px" }}>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="pre_no" />
            <input type="hidden" name="ot" />
            <input type="hidden" name="oid" />
            <textarea
              name="remark"
              style={{ display: "inline-block" }}
              className="form-control"
              defaultValue={values.remark}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            <p>{errors.remark}</p>

            <br />
            <input
              type="checkbox"
              name="whatsapp"
              id="whatsapp-id"
              onChange={() => setFieldValue("whatsapp", "Y")}
            />
            <label htmlFor="whatsapp-id" className="label-bold">
              Whatsapp
            </label>

            <input
              type="checkbox"
              name="telephone"
              id="telephone-id"
              onChange={() => setFieldValue("telephone", "Y")}
            />
            <label htmlFor="telephone-id" className="label-bold">
              Telephone
            </label>

            <input
              type="checkbox"
              name="status_refund"
              id="statusRefund-id"
              onChange={() => setFieldValue("status_refund", "Y")}
            />
            <label htmlFor="statusRefund-id" className="label-bold">
              {" "}
              Refund
            </label>

            <input
              type="checkbox"
              name="voided"
              id="voided-id"
              onChange={() => setFieldValue("voided", "Y")}
            />
            <label htmlFor="voided-id" className="label-bold">
              Voided
            </label>

            <input
              type="checkbox"
              name="chargeback"
              id="chargeback-id"
              onChange={() => setFieldValue("chargeback", "Y")}
            />
            <label htmlFor="chargeback-id" className="label-bold">
              Chargeback
            </label>

            <br />
            <br />
            <button
              type="submit"
              className="blue-btn"
              value="Remark"
              name="submit"
            >
              Save Comment
            </button>
            <button
              type="button"
              className="blue-btn"
              value="Awaiting"
              name="submit"
              id="Awaiting"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Response
            </button>
            <button
              type="button"
              className="blue-btn"
              value="Refund"
              name="submit"
              id="Refund"
              onClick={handleOrderStatus}
            >
              Move to Completed Refunds
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Completed"
              name="submit"
              id="Completed"
              onClick={handleOrderStatus}
            >
              Move to Completeded Orders
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
