import React, { useEffect } from "react";
import { Container, StyledContainer } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  getPaymentGateways,
  updatePaymentGateway,
} from "../../../../redux/gateWaySlice";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialValues = {
  gatewayName: "",
};

const GateWaySchema = yup.object({
  gatewayName: yup.string().required("Please select gateway"),
});

const Gateway = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentGateways = useSelector(
    (state) => state?.gateway?.paymentGateways
  );

  useEffect(() => {
    dispatch(getPaymentGateways())
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/auth/login");
        }
      });
    document.title = "Gateway";
  }, [dispatch, navigate]);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: GateWaySchema,
    onSubmit: (values) => {
      dispatch(updatePaymentGateway(encryptVal(values.gatewayName)))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  return (
    <StyledContainer>
      <Container>
        <div className="card">
          <div className="header">
            <h2>
              <strong>Gateway Setting</strong>
            </h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit}>
              <label htmlFor="gateway_name">
                <strong>Gateway</strong>
              </label>
              <select
                className="form-control gateway_name"
                name="gatewayName"
                value={values.gatewayName}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Gateway</option>
                {paymentGateways?.map((item, i) => {
                  return (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.gatewayName}</p>
              <div className="col-sm-12">
                <br />
                <button type="submit">Active Gateway</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </StyledContainer>
  );
};

export default Gateway;
