import styled from "styled-components";

export const StyledContainer = styled.div.attrs({
  className: "row clearfix",
})``;

export const Container = styled.div.attrs({
  className: "col-lg-12 col-md-12 col-sm-12",
})`
  .card {
    transition: 0.5s;
    border: 0;
    position: relative;
    width: 100%;
    background: transparent;
    border-radius: 0.55rem;
  }
  .card .header {
    color: #424242;
    padding: 20px 0;
    position: relative;
    box-shadow: none;
  }
  .card .header h2 {
    font-size: 16px;
    color: #212121;
    position: relative;
  }
  .body {
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }

  .body {
    box-shadow: 0 0 18px rgb(0 0 0 / 8%);
  }
  .form-control {
    background-color: transparent;
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #2c2c2c;
    line-height: normal;
    font-size: 0.93em;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 3rem;
  }
  & button {
    border-width: 2px;
    font-weight: 400;
    font-size: 0.93em;
    line-height: 1.35em;
    margin: 3px 1px;
    border: none;
    border-radius: 0.1875rem;
    padding: 11px 22px;
    cursor: pointer;
    background-color: #888;
    color: #fff;
    background: #313740;
  }

  & p {
    color: red;
  }
`;
