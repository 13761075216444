import styled from "styled-components";

export const DefenceContainer = styled.div`
  padding: 20px;
  .header {
    float: right;
  }

  &h5 {
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  .main {
    margin-top: 23rem;
  }
  & a {
    text-decoration: none;
    color: #f96332;
  }

  & span {
    text-decoration: underline;
    font-weight: 500;
  }
  .upload-doc-link {
    border: 1px solid black;
    border-color: #18f;
    padding: 11px;
    border-radius: 14px;
    background-color: #18f;
    color: white;
  }

  & button {
    color: #fff;
    background: #0d6efd;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    float: right;
  }

  .flex-class {
    display: flex;
  }
  .list-content {
    padding-left: 20px;
  }
  & img,
  svg {
    vertical-align: middle;
    width: 66rem;
  }

  .container-box {
    border: 2px solid black;
  }

  .arrival-img {
    width: 20rem;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }

  .btn-success {
    background-color: #18ce0f;
    color: #fff;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }

  .sign-block{
    margin-top:20px;
    border: 3px solid black;
    padding: 5px;

  }

  .download-history {
    border: 3px solid black;
    padding: 5px;

    & tr {
      border-bottom: 1px solid #fff;
    }
  }
`;
