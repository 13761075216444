import styled from "styled-components";

export const SectionStyled = styled.section.attrs({
  className: "home content",
})`
  // background-color: #f4f7f6;
  margin: 0 0 0 326px;
  transition: 0.5s;
  z-index: 9;
  position: relative;
  border-radius: 0.55rem;

  @media (max-width: 1150px) {
    margin: 0 0 0 50px;
  }
`;

export const ContainerFluid = styled.div.attrs({
  className: "container-fluid",
})``;

export const StyledContainer = styled.div.attrs({ className: "row clearfix" })`
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(0 * var(--bs-gutter-x));
`;

export const BlockHeader = styled.div.attrs({ className: "block-header" })`
  padding: 10px 10px;
  color: #616161;

  & li {
    list-style: none;
  }

  .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;
  }
`;

export const StyledBody = styled.div`
  padding: 10px 0;
  padding-left: 10px;
`;

export const StyledCard = styled.div.attrs({
  className: "row clearfix main-folder-section",
})``;

export const Card = styled.div.attrs({
  className: "col-lg-2 col-md-6 top-folder",
})`
  & a {
    color: #313740;
    text-decoration: none;
    background-color: transparent;
  }

  & h3 {
    color: #313740;
  }
  & p {
    width: 50px;
    height: 50px;
    padding: 7px;
    border-radius: 8px;
    position: absolute;
    left: 12px;
    top: 50%;
    margin-top: -25px;
    margin-bottom: 0;
    box-shadow: 0 0 18px rgb(0 0 0 / 8%);
    text-align: center;
  }

  & span {
    font-size: 12px;
    font-weight: 500;

    @media (max-width: 1685px) {
      font-size: 11px;
      font-weight: 500;
    }
    @media (max-width: 1575px) {
      font-size: 7.5px;
      font-weight: 500;
    }
    @media (max-width: 1160px) {
      font-size: 10px;
      font-weight: 500;
    }
    @media (max-width: 1015px) {
      font-size: 9.8px;
      font-weight: 500;
    }
    @media (max-width: 1000px) {
      font-size: 14px;
      font-weight: 500;
    }
  }

  & h3 {
    font-size: 22px;
    margin: 0;

    @media (max-width: 1685px) {
      font-size: 18px;
    }
    @media (max-width: 1540px) {
      font-size: 12px;
    }
    @media (max-width: 1000px) {
      font-size: 22px;
      margin: 0;
    }
  }
  & img {
    height: -webkit-fill-available;
  }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
    gap: 16rem;

    & ul{
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
    }

    & li{
        padding-left: 0.5rem;
        list-style:none;
        display: contents;

    }
`;

export const StyledInput = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;

  & input {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #2c2c2c;
    line-height: normal;
    font-size: 0.93em;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 3rem;
    padding-top: 5px;
    width: 69rem;
  }
`;

export const CardDiv = styled.div`
  transition: 0.5s;
  border: 0;
  position: relative;
  width: 100%;
  background: transparent;
  border-radius: 0.55rem;
  text-align: center !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  & p {
    width: 50px;
    height: 50px;
    padding: 7px;
    border-radius: 8px;
    position: absolute;
    left: 12px;
    top: 50%;
    margin-top: -25px;
    margin-bottom: 0;
    box-shadow: 0 0 18px rgb(0 0 0 / 8%);
    text-align: center;
  }

  & span {
    font-size: 10px;
    font-weight: 600px;
  }

  & img {
    height: -webkit-fill-available;
  }
`;

export const StyledMain = styled.div`
  margin-top: 3rem;
`;
