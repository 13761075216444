import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import remarkService from "../services/remarkService";

export const addNewRemark = createAsyncThunk(
  "/admin/add-new-remark",
  async (remark) => {
    try {
      const response = await remarkService.addNewRemark(remark);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const remarkSlice = createSlice({
  name: "remark",
  initialState: {
    loading: false,
    error: null,
    // remark:{},
  },
  reducers: {},

  extraReducers: (builder) => {},
});

export default remarkSlice.reducer;
