import styled from "styled-components";
export const MainContainer = styled.div.attrs({ className: "col-lg-12" })`
  .card {
    transition: 0.5s;
    border: 0;
    position: relative;
    width: 100%;
    background: transparent;
    border-radius: 0.55rem;
  }
  .card .header {
    color: #424242;
    padding: 20px 0;
    position: relative;
    box-shadow: none;
  }
  .card .header h2 {
    font-size: 16px;
    color: #212121;
    position: relative;
  }
  .card.body:hover {
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
    transition: all 0.2s ease-in-out;
  }
  .body {
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }
  .body {
    box-shadow: 0 0 18px rgb(0 0 0 / 8%);
  }
`;
