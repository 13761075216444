import ApiService from "./ApiService";

export default class OrderService {
  static getCount = () => ApiService.get("/admin/get-all-folder-count");

  static getMailCount = () => ApiService.get("/admin/get-mail-count");

  static getOrdersData = (order) =>
    ApiService.get(`/admin/get-orders-list/${order}`);

  static getOrdersDetails = (orderId) =>
    ApiService.get(`/admin/get-orders-details/${orderId}`);

  static deleteOrdersDetails = (deleteData) =>
    ApiService.put(`/admin/change-order-status`, deleteData);

  static addNewRemark = (remark) =>
    ApiService.post("/admin/add-new-remark", remark);

  static sendIncompletePaymentMail = (invoiceData) =>
    ApiService.put("/admin/send-invoice", invoiceData);

  static sendRefundMail = (refundData) =>
    ApiService.put("/admin/move-to-complete-refund", refundData);

  static uploadVisaDocument = (data) =>
    ApiService.post("/admin/upload-visa-document", data);

  static resendMail = (data) => ApiService.post("/admin/resend-mail", data);

  static ChangeOrderStatus = (data) =>
    ApiService.put(`/admin/change-order-status`, data);

  static searchByOrderId = (orderId) =>
    ApiService.post("/admin/search-order-id", orderId);

  static defencePack = (orderId) =>
    ApiService.post("/admin/defence-pack-details", orderId);

  static GetDefencePackPdf = (data) =>
    ApiService.post("/admin/get-defence-pack-pdf", data, {
      responseType: "blob",
    });
}
