import React from 'react';
import { Route, Routes } from 'react-router-dom';
//import Signup from '../../pages/Signup';
import Login from '../../pages/AdminLogin/Login';

const SignupLayout = (props) => (
  <div className='container'>
    <Routes>
      <Route path="/login" element={<Login />} />
    </Routes>
  </div>
);

export default SignupLayout;
