import React from "react";
import { StyledChild, StyledHeader } from "./style";
import { FaEnvelope } from "react-icons/fa";
import { BsFillArrowDownCircleFill } from "react-icons/bs";


const Header = () => {
  return (
    <StyledHeader>
      <StyledChild>
        <span>
          <FaEnvelope/>
          inquiries@sg-arrivalcard.online
        </span>
      </StyledChild>
      <div>
      <select className="form-select" aria-label="Default select example" >
      <option > Select Language</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </select>
    <span><BsFillArrowDownCircleFill/></span>
      </div>
    </StyledHeader>
  );
};

export default Header;
