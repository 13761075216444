import ApiService from "./ApiService";

export default class manageTeamService {
  static getTeamMemberList = () => ApiService.get("/admin/team-member-list");

  static teamMemberRegistration = (formData) =>
    ApiService.upload("/admin/team-member-registration", formData);

  static deleteTeamMember = (data) =>
    ApiService.put("/admin/delete-team-member", data);

  static getTeamMemberById = (teamMemberId) =>
    ApiService.get(`/admin/team-member-info/${teamMemberId}`);

  static updateTeamMember = (updateData) =>
    ApiService.post("/admin/update-team-member", updateData);
}
