import AdminNavbar from "./AdminNavbar";
import {
  SectionStyled,
  ContainerFluid,
  StyledContainer,
} from "./AdminNavbar/style";
import AdminSidebar from "./AdminSidebar";
import Main from "./Main";
import "../../../../src/assests/css/style.css";

const AdminLayout = (props) => (
  <SectionStyled>
    <ContainerFluid>
      <AdminNavbar {...props} />
      <AdminSidebar {...props} />
      <StyledContainer>
        <Main {...props} />
      </StyledContainer>
    </ContainerFluid>
  </SectionStyled>
);

export default AdminLayout;
