import styled from "styled-components";
import image from "../../assests/img/banner-1.jpg";

export const StyledArrival = styled.div`
  background: antiquewhite;
  background-image: url(${image});
  height: 100vh;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all 10s ease-out 0s;

  & h3 {
    text-align: center;
    padding-top: 8rem;
  }
`;

export const StyledLink = styled.div`
  border: 1px solid #8d2029;
  width: 23rem;
  height: 8rem;
  margin-left: 44rem;
  margin-top: 3rem;
  border-radius: 5rem;
  background-color: #8d2029;
  padding: 10px 40px;

  @media (max-width: 1300px) {
    margin-left: 25rem;
  }

  @media (max-width: 850px) {
    margin-left: 15rem;
  }

  @media (max-width: 650px) {
    margin-left: 4rem;
  }

  &:hover {
    background-color: #212529;
    transition: background-color 0.5s;
  }

  & a {
    text-decoration: none;
    color: #fff;
    line-height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const StyledCardDiv = styled.div`
  margin-top: 18.5rem;
  background-color: #002147;
  padding: 60px 42px;
  display: flex;
  gap: 2rem;

  @media (max-width: 1100px) {
    display: block;
  }
`;

export const StyledCard = styled.div`
  height: 18rem;
  background-color: #8d2029;
  width: 35rem;

  @media (max-width: 640px) {
    width: 25rem;
  }
  
 

  & h5{
      text-align:center;
      color: #fff;
      font-weight: bold;
      padding:4rem;

  }

& a{
    text-decoration: none;
    color: #fff;
    padding-left: 15rem;

}

& p{
    margin-top: -35px;
    margin-bottom:     margin-bottom: 1rem;
    color: #fff;
    opacity: 70%;
    margin-bottom: 30px;
    // max-width: 420px;
}
`;

export const StyledSection = styled.div`
  // margin-top: 50rem;

  & div {
    margin-bottom: 25px;
    padding: 0 15rem;

    @media (max-width: 960px) {
      padding: 0 5rem;
    }
  }

  & h5 {
    text-align: center;
    padding-top: 2rem;
  }
`;

export const StyleScroll = styled.div`
     background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 20px 0;

    .Container{
      max-width: 1320px;

    }

  .owl-carousel {
    loop:true,
    margin:5,
    nav:true,
  }

  & img{
    width:128px;
     height:128px;
      display: block;
    margin: 0 auto;
     padding-top:20px;
}
.img_div{
  width: 120px;
    height: 120px;
    line-height: 120px;
    border: 1px solid #ededed;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.item{
  background: #fff;
    text-align: center;
    transition: all 0.4s ease-out 0s;
    padding: 20px;
    border: 2px solid #0e0e0f;
}
.owl-carousel .owl-item img {
  display: block;
  width: 59%;
  height: 100px;
}
.owl-theme .owl-controls .owl-page span {
  background: none repeat scroll 0 0 #869791;
  border-radius: 20px;
  display: block;
  height: 12px;
  margin: 5px 7px;
  opacity: 0.5;
  width: 12px;
}
`;

export const Card = styled.div`
  display: flex;
  gap: 5px;
  padding: 0 15rem;
`;

export const StyledDiv = styled.div`
  padding: 3rem 15rem;
  display: flex;

  @media (max-width: 1000px) {
    padding: 3rem 0rem;
  }

  @media (max-width: 1000px) {
    display: block;
  }

  & img {
    width: 25rem;
    height: 16rem;
    padding-bottom: 3rem;
  }
`;

export const ChildDiv = styled.div`
  padding-left: 10rem;
  width: 50%;

  @media (max-width: 650px) {
    padding-left: 5rem;
  }
`;

export const Container = styled.div`
  padding: 40px 120px;
  color: #fff;
  background: #002147;
`;

export const StyledContainer = styled.div`
  padding: 40px 120px;
`;

export const TableContainer = styled.div`
  padding: 2rem 15rem;

  @media (max-width: 850px) {
    padding: 2rem 10rem;
  }

  @media (max-width: 750px) {
    padding: 2rem 4rem;
  }

  @media (max-width: 650px) {
    padding: 0rem 0rem;
  }

  & h5 {
    text-align: center;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  // overflow-x:auto;

  & td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: blue;

    @media (max-width: 650px) {
      padding: 0px;
    }
  }

  & tr:nth-child(even) {
    background-color: #dddddd;
  }
`;

export const FooterDiv = styled.div`
  padding: 5rem 0;
  background-color: #002147;
  display: flex;
  color: #fff;

  @media (max-width: 1200px) {
    padding-left: 1rem;
  }

  @media (max-width: 850px) {
    display: block;
  }

  & li {
    color: #fff;
    opacity: 70%;
    list-style-type: none;

    img,
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  a {
    color: #a4acb5;
    text-decoration: none;
  }
`;

export const StyledList = styled.div`
  width: 45%;
  padding-left: 20rem;

  @media (max-width: 1200px) {
    padding-left: 8rem;
  }

  @media (max-width: 900px) {
    padding-left: 1rem;
  }
`;

export const HomeFooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999999999999;
  background: #000;
  color: #fff;
  font-size: 14px;
  padding: 8px 10px;
  text-align: center;

  @media (max-width: 1830px) {
    width: 100%;
  }

  & p {
    color: #fff;
  }

  & a {
    color: var(--bs-link-color);
    text-decoration: none;
  }

  & button {
    border: 1px solid #ededed;
    border-color: transparent;
    border-radius: 0;
    background: #8d2029;
    color: #fff;
    line-height: 1;
    padding: 0.6em 1em 0.4em;

    &:hover {
      color: #212529;
    }
  }
`;

export const StyledButton = styled.div`
  // position: absolute;
  top: 40%;
  left: 0;
  width: 100%;

  .button_prev {
    left: 10px;
    top: -150px;
  }

  .button_next {
    float: right;
    right: 10px;
    top: -150px;
  }

  .services__two-button-prev i {
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    color: #8d2029;
  }

  & button {
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    border: 1px solid #8d2029;
    font-size: 18px;
    color: #8d2029;
    background-color: #fff;
    border-radius: 5px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
`;
