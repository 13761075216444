import styled from "styled-components";

export const Aside = styled.div.attrs({ className: "right-menu" })`
  display: block;

  .sidebar {
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-family: "Muli", sans-serif;
    border-radius: 0.1875rem;
    height: calc(100vh - 20px);
    margin: 10px;
    background: #f4f7f6;
    position: fixed;
    top: 0px;
    left: 50px;
    z-index: 10;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 1150px) {
      display: none;
    }

    @media (max-width: 770px) {
      width: 87%;
    }
    @media (max-width: 670px) {
      width: 82%;
    }

    @media (max-width: 570px) {
      width: 77%;
    }
    @media (max-width: 767px) .sidebar {
      width: calc(100% - 65px);
    }

    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  .user-info {
    position: relative;
    border-bottom: 1px solid #eee;
    color: #424242;

    .image img {
      width: 80px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      vertical-align: bottom !important;
      border: 3px solid #fff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    }

    .detail a {
      display: inline-block !important;
      padding: 10px 5px !important;
    }
  }

  .menu-toggle {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .menu {
    position: relative;
    .list {
      padding: 10px;
      list-style: none;
      li.active.open {
        border-radius: 0.1875rem;
        a,
        a::before,
        span {
          color: #fff;
        }
        > :first-child span {
          font-weight: 700;
        }
      }

      .header {
        font-size: 11px;
        font-weight: 700;
        padding: 8px 12px;
        position: relative;
        color: #616161;
      }
    }
  }

  & a {
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    color: #313740;
    text-decoration: none;
    display: flex;
    width: max-content;
    height: 55px;

    & img {
      min-width: 14px;
      height: 15px;
    }
  }

  & span {
    margin: 0 0 0 12px;
  }

  .span-class {
    padding: 2px 8px;
    text-transform: uppercase;
    font-size: 0.7142em;
    line-height: 12px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
    margin-bottom: 5px;
    // border-radius: 0.875rem;

    border-color: #888;
    color: #888 !important;
    float: right;
  }

  .show {
    display: block;
  }
  .hide {
    @media (max-width: 1150px) {
      display: none;
    }
  }
`;
export const MiniLeftBar = styled.div.attrs({ className: "minileftbar" })`
  width: 50px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 99;
  background: #22252b;

  .menu_list {
    list-style: none;
    margin: 0 !important;

    li a {
      padding: 10px;
      color: #fff;
    }

    .bars-icon {
      display: none;
      vertical-align: middle;
      margin-left: -16px;

      @media (max-width: 1150px) {
        display: block;
      }
    }

    .power {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .badgebit {
      position: relative;
    }

    .badgebit .notify {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    @media (max-width: 1169px) {
      a.menu-sm {
        display: none !important;
      }
    }

    @media (max-width: 767px) {
      margin: -5px !important;
    }
  }

  @media (max-width: 767px) {
    width: 36px;
    .bars::before,
    .bars::after {
      left: 6px;
    }
  }
`;

export const Menu = styled.div.attrs({ className: "menu" })`
  position: relative;

  & ul {
    padding-right: 10px;
    padding-left: 1rem;
  }

  & li {
    list-style: none;
  }
`;

export const UserInfo = styled.div.attrs({ className: "user-info m-b-20" })`
  position: relative;
  border-bottom: 1px solid #eee;
  color: #424242;
  text-align: center;

  #profile_pic {
    width: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    vertical-align: bottom !important;
    border: 3px solid #fff;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 50%);
  }
`;

export const StyledLogo = styled.div.attrs({ className: "detail" })`
  margin-bottom: 10px;

  & h6 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const OverlayWrapper = styled.div.attrs({ className: "overlay" })`
  @media (max-width: 1150px) {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: ${(props) => (props.overlay ? `block` : `none`)};
  }
`;
