import React from "react";
import {
  FirstDiv,
  StyledButton,
  StyledContact,
  StyledContainer,
  MainDiv,
} from "./style";
import ReCAPTCHA from "react-google-recaptcha";
import { BsArrowRight } from "react-icons/bs";

const ContactUs = () => {
  return (
    <StyledContainer>
      <StyledContact>
        <h5>
          Mandatory fields are indicated by a red asterisk
          <span className="asterisk">*</span>
        </h5>
        <form>
          <MainDiv>
            <FirstDiv>
              <label htmlFor="fname">
                Your Name <span className="asterisk">*</span>
              </label>
              <br />
              <input
                type="text"
                id="fname"
                name="first_name"
                placeholder="Emri i plote"
              />
            </FirstDiv>
            <FirstDiv>
              <label htmlFor="email">
                Your Email <span className="asterisk">*</span>
              </label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Your Email Address"
              />
            </FirstDiv>
          </MainDiv>

          <br />
          <label htmlFor="orderno">Your Order Number</label>
          <br />
          <input
            type="text"
            id="fname"
            name="first_name"
            placeholder="Emri i plote"
          />
          <br />
          <label htmlFor="fname">
            Your Message <span className="asterisk">*</span>{" "}
          </label>
          <br />
          <textarea name="message">Message </textarea>

          <br />
          <StyledButton>
            <button>
              Send Message <BsArrowRight />
            </button>
            <div>
              <ReCAPTCHA sitekey="6LdrAD4kAAAAAE6nNH32yNLzGWrovGNJaYpJgFoN" />
            </div>
          </StyledButton>
        </form>
      </StyledContact>
    </StyledContainer>
  );
};

export default ContactUs;
