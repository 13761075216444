import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainContainer } from "./style";
import ReactDataTable from "../../../../Components/ReactDataTable/ReactDataTable";
import { getOrdersData } from "../../../../redux/orderSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const IncompleteOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const IncompleteOrdersData = useSelector((state) => state?.order?.OrdersData);
  let page = location.pathname.slice(7).replace("-", " ");

  useEffect(() => {
    dispatch(getOrdersData("incompletedorder"))
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.data?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/auth/login");
        }
      });
    document.title = "Incompleted Orders";
  }, [dispatch, navigate]);

  const tableData = IncompleteOrdersData;

  return (
    <MainContainer>
      <div className="col-lg-12">
        <div className="card">
          <div className="header">
            <h2>
              <strong className="ms-3">Incompleted orders</strong>
            </h2>
          </div>
          <div className="body">
            <form>
              <input
                type="hidden"
                id="order_id"
                className="order_id"
                name="order_id"
                value=""
              />
              <ReactDataTable data={tableData} page={page} />
            </form>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default IncompleteOrders;
