import React, { useEffect } from "react";
import { MainContainer } from "./style";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../../../../Components/ReactDataTable/index";
import { getOrdersData } from "../../../../redux/orderSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const NewOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const NewOrderData = useSelector((state) => state?.order?.OrdersData);
  const token = localStorage.getItem("user");

  useEffect(() => {
    if (token) {
      dispatch(getOrdersData("neworder"))
        .unwrap()
        .then((res) => {
          if (res.status === 401) {
            toast.error(`${res?.data?.message}`, {
              className: "toast-message",
            });
            localStorage.removeItem("user");
            navigate("/auth/login");
          }
        });
    }
    document.title = "New Orders";
  }, [dispatch, navigate, token]);

  const tableData = NewOrderData;

  return (
    <MainContainer>
      <div className="card">
        <div className="header">
          <h2>
            <strong>Recent Orders</strong>
          </h2>
        </div>
        <div className="body">
          <form>
            <input
              type="hidden"
              id="order_id"
              className="order_id"
              name="order_id"
              value=""
            />
            <ReactDataTable data={tableData} />
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

export default NewOrders;
