import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { SearchField, ButtonWrapper } from "./style";
import { Link } from "react-router-dom";
import { encryptVal } from "../../utility/utility";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";
import {
  deleteOrdersDetails,
  getCount,
  sendIncompletePaymentMail,
  sendRefundMail,
} from "../../redux/orderSlice";
import moment from "moment";

const ReactDataTable = ({
  data,
  hideButtonWrapper,
  buttonWrapper,
  customColumns,
  customOrdersColumns,
  customOrdersData,
  customData,
  deleteOrders,
  page,
}) => {
  hideButtonWrapper =
    typeof hideButtonWrapper !== "undefined" ? hideButtonWrapper : false;
  buttonWrapper =
    typeof buttonWrapper === "undefined" ? (
      <ButtonWrapper>
        <button
          type="button"
          name="submit"
          value="Delete"
          className="btn btn-success"
          onClick={() => setShow(true)}
        >
          Delete Order
        </button>
      </ButtonWrapper>
    ) : typeof buttonWrapper === "string" && buttonWrapper === "refund" ? (
      <ButtonWrapper>
        <button
          type="button"
          name="submit"
          value="Delete"
          className="btn btn-success"
          onClick={() => setShow(true)}
        >
          Delete Order
        </button>{" "}
        <button
          type="button"
          name="submit"
          value="invoice"
          className="btn btn-success"
          onClick={() =>
            dispatch(sendRefundMail(selectedData))
              .unwrap()
              .then((res) => {
                if (res.status === 1) {
                  toast.success(`${res.message}`, {
                    className: "toast-message",
                  });
                } else {
                  if (res.status === 0) {
                    toast.error(`${res.message}`, {
                      className: "toast-message",
                    });
                  }
                }
              })
          }
        >
          Refund
        </button>
      </ButtonWrapper>
    ) : typeof buttonWrapper === "string" && buttonWrapper === "invoice" ? (
      <ButtonWrapper>
        <button
          type="button"
          name="submit"
          value="Delete"
          className="btn btn-success"
          onClick={() => setShow(true)}
        >
          Delete Order
        </button>{" "}
        <button
          type="button"
          name="submit"
          value="invoice"
          className="btn btn-success"
          onClick={() =>
            dispatch(sendIncompletePaymentMail(selectedData))
              .unwrap()
              .then((res) => {
                if (res.status === 1) {
                  toast.success(`${res.message}`, {
                    className: "toast-message",
                  });
                } else {
                  if (res.status === 0) {
                    toast.error(`${res.message}`, {
                      className: "toast-message",
                    });
                  }
                }
              })
          }
        >
          Send Invoice
        </button>
      </ButtonWrapper>
    ) : (
      buttonWrapper
    );
  let filteredArray = [];
  let columns;
  let Data = [];
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [changePage, setChangePage] = useState(1);
  const [changeRowsPerPage, setChangeRowsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData(orderId);
  };

  const deleteOrder = (orderID) => {
    let data = {
      orderId: orderID,
      permanentDeletedFlag: deleteOrders ? deleteOrders : false,
      updatingStatus: "Deleted",
    };
    dispatch(deleteOrdersDetails(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(getCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  if (
    data &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0
  ) {
    Data = data?.map((item) => {
      return {
        id: item?.order_id,
        name: item?.full_name,
        email: item?.email,
        phone: item?.phone,
        status: item?.process_status,
        date: moment(item?.create_ts).format("DD-MM-YYYY h:mm:ss"),
      };
    });
  } else if (typeof customData !== "undefined" && customData?.length !== 0) {
    Data = customData?.map((item, index) => {
      return {
        id: item?.id,
        name: item?.name,
        email: item?.email,
        password: item?.password,
        type: item?.type,
      };
    });
  } else if (
    typeof customOrdersData !== "undefined" &&
    customOrdersData?.length !== 0
  ) {
    Data = customOrdersData?.map((item) => {
      return {
        id: item?.order_id,
        name: item?.full_name,
        email: item?.email,
        phone: item?.phone,
        status:
          item?.process_status === "Refund"
            ? "Refund Incomplete"
            : item?.process_status === "Complete Refunds"
            ? "Refund Complete"
            : item?.process_status,
        date: item?.create_ts,
        refundDate: item?.refund_date ? item?.refund_date : "-",
        processDate: moment(item?.processing_date).format("DD/MM/Y"),
        processMonth: item?.process_month,
      };
    });
  }

  if (filterText !== "") {
    filteredArray = Data?.filter(
      (item) =>
        (item?.id).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.name).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.email).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.phone).toLowerCase().includes(filterText.toLocaleLowerCase())
    ).map((item) => {
      return {
        id: item.id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        status: item.status,
        date: item.date,
      };
    });
    Data = filteredArray;
  }
  if (!customColumns && !customOrdersColumns) {
    columns = [
      {
        name: "Order ID",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Telephone",
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: "Date & Time",
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Action",
        selector: (row) =>
          Data?.length ? (
            <span>
              <Link
                to={`/admin/view-order/${encryptVal(row.id)}`}
                style={{
                  textDecoration: "none",
                  color: "#f96332",
                }}
              >
                View
              </Link>{" "}
              <Link
                to=""
                onClick={() => handleShow(row.id)}
                style={{
                  textDecoration: "none",
                  color: "#f96332",
                }}
              >
                Delete
              </Link>
            </span>
          ) : (
            " "
          ),
      },
    ];
  } else if (!columns && !customOrdersColumns) {
    columns = customColumns;
  } else {
    columns = customOrdersColumns;
  }

  const handleChange = (state) => {
    const allDeleteData = state.selectedRows.slice(
      changeRowsPerPage * (changePage - 1),
      changePage * changeRowsPerPage
    );
    const newData = allDeleteData.map((item) => item.id);
    setSelectedData(newData);
  };
  return (
    <>
      <Modal
        show={show}
        close={handleClose}
        size={"s"}
        confirmAlert={true}
        noEvent={() => handleClose()}
        yesEvent={() => deleteOrder(selectedData)}
      ></Modal>
      <SearchField>
        <input
          type="text"
          id="search_field"
          className="search_field"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          name="search_field"
          placeholder="Search"
        />
      </SearchField>
      <DataTable
        selectableRows={
          customColumns
            ? false
            : customOrdersData
            ? true
            : Data?.length
            ? true
            : false
        }
        columns={columns}
        data={Data?.length ? Data : [{}]}
        fixedHeader={true}
        fixedHeaderScrollHeight="550px"
        pagination={Data?.length ? true : false}
        paginationComponentOptions={{ selectAllRowsItem: true }}
        noDataComponent="No records found"
        onSelectedRowsChange={handleChange}
        onChangePage={(e) => {
          setChangePage(e);
        }}
        onChangeRowsPerPage={(e) => {
          setChangeRowsPerPage(e);
        }}
      />
      {!Data?.length && (
        <p style={{ textAlign: "center" }}>{"No records found"}</p>
      )}
      {!hideButtonWrapper && buttonWrapper}
    </>
  );
};

export default ReactDataTable;
