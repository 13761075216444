import React, { useEffect, useState } from "react";
import { MainContainer, ButtonWrapper } from "./style";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../../../../Components/ReactDataTable/index";
import {
  getOrdersData,
  deleteOrdersDetails,
  getCount,
} from "../../../../redux/orderSlice";

import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { encryptVal } from "../../../../utility/utility";
import Modal from "../../../../Components/Modal/Modal";

const FutureOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FutureOrderData = useSelector((state) => state?.order?.OrdersData);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleClose = () => setShow(false);

  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData(orderId);
  };
  let futureOrderArray = [];
  const [datatableData, setDatatableData] = useState({
    renderDatatable: false,
    data: [],
    month: "",
  });
  const deleteOrder = (orderID) => {
    let data = {
      orderId: orderID,
      permanentDeletedFlag: false,
      updatingStatus: "Deleted",
    };
    dispatch(deleteOrdersDetails(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(getCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telephone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Processing Date ",
      selector: (row) => row.processDate,
      sortable: true,
    },
    {
      name: "Process Month",
      selector: (row) => row.processMonth,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) =>
        FutureOrderData?.length ? (
          <span>
            <Link
              to={`/admin/view-order/${encryptVal(row.id)}`}
              style={{
                textDecoration: "none",
                color: "#f96332",
              }}
            >
              View
            </Link>{" "}
            <Link
              to="#"
              onClick={() => handleShow(row.id)}
              style={{
                textDecoration: "none",
                color: "#f96332",
              }}
            >
              Delete
            </Link>
          </span>
        ) : (
          ""
        ),
    },
  ];
  const [futureOrderData, setFutureOrderData] = useState([]);
  useEffect(() => {
    dispatch(getOrdersData("futureorder"))
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.data?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/auth/login");
        }
      });
    document.title = "Future Orders";
  }, [dispatch, navigate]);

  useEffect(() => {
    const tableData = FutureOrderData;
    for (let data of tableData) {
      if (typeof futureOrderArray[data.process_month] !== "undefined") {
        futureOrderArray[data.process_month] = {
          count: futureOrderArray[data.process_month]?.count + 1,
          monthData: [...futureOrderArray[data.process_month].monthData, data],
        };
      } else {
        futureOrderArray[data.process_month] = { count: 1, monthData: [data] };
      }
    }
    setFutureOrderData(futureOrderArray);
    setDatatableData({
      renderDatatable: datatableData.renderDatatable,
      data: futureOrderArray[datatableData.month]?.monthData,
      month: datatableData.month,
    });
  }, [FutureOrderData]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <MainContainer>
      <div className="card">
        <div className="header">
          <h2>
            <strong>Future Orders</strong>
          </h2>
        </div>
        {datatableData.renderDatatable && (
          <ButtonWrapper>
            <button
              className="btn btn-success"
              onClick={() => {
                setDatatableData({
                  renderDatatable: false,
                  data: [],
                  month: "",
                });
              }}
            >
              Back
            </button>
          </ButtonWrapper>
        )}
        {!datatableData.renderDatatable ? (
          <div className="body">
            <div className="row clearfix">
              {months.map((month, i) => {
                return (
                  <div className="col-lg-3 col-md-6" key={i}>
                    <div className="card text-center">
                      {typeof futureOrderData[month] !== "undefined" ? (
                        <Link
                          to="#"
                          onClick={() => {
                            setDatatableData({
                              renderDatatable: true,
                              data: futureOrderData[month].monthData,
                              month: month,
                            });
                          }}
                        >
                          <div className="body">
                            <span>
                              {month}{" "}
                              {typeof futureOrderData[month] === "undefined"
                                ? 0
                                : `(${futureOrderData?.[month].count})`}
                            </span>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          onClick={() => {
                            setDatatableData({
                              renderDatatable: true,
                              data: [],
                              month: month,
                            });
                          }}
                        >
                          <div className="body">
                            <span>
                              {month}{" "}
                              {typeof futureOrderData[month] === "undefined"
                                ? 0
                                : `(${futureOrderData?.[month].count})`}
                            </span>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="body">
            <form>
              <input
                type="hidden"
                id="order_id"
                className="order_id"
                name="order_id"
                value=""
              />

              <ReactDataTable
                customOrdersColumns={columns}
                customOrdersData={datatableData?.data}
              />
              <Modal
                show={show}
                close={handleClose}
                size={"s"}
                confirmAlert={true}
                noEvent={() => handleClose()}
                yesEvent={() => deleteOrder(selectedData)}
              ></Modal>
            </form>
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default FutureOrders;
