import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../../../../Components/ReactDataTable/index";
import { getOrdersData } from "../../../../redux/orderSlice";
import { MainContainer } from "./style";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const DeletedOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DeletedOrderData = useSelector((state) => state?.order?.OrdersData);

  useEffect(() => {
    dispatch(getOrdersData("deletedorder"))
    .unwrap()
    .then((res) => {
      if (res.status === 401) {
        toast.error(`${res}`, {
          className: "toast-message",
        });
        localStorage.removeItem("user");
        navigate("/auth/login");
      }
    });
    document.title = "Deleted Orders";
  }, [dispatch,navigate]);

  const tableData = DeletedOrderData;

  return (
    <MainContainer>
      <div className="col-lg-12">
        <div className="card">
          <div className="header">
            <h2>
              <strong>Delete Orders</strong>
            </h2>
          </div>
          <div className="body">
            <form>
              <input
                type="hidden"
                id="order_id"
                className="order_id"
                name="order_id"
                value=""
              />
              <ReactDataTable data={tableData} deleteOrders={true} />
            </form>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default DeletedOrders;
