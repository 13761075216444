import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../services/OrderService";
import { toast } from "react-toastify";

export const getCount = createAsyncThunk("/order/getCount", async () => {
  try {
    const response = await OrderService.getCount();
    const data = await response.data;
    if (response.status === 401) {
      toast.error(`${response.message}`, {
        className: "toast-message",
      });
      localStorage.removeItem("user");
    }
    return data;
  } catch (error) {
    return error.response.data;
  }
});

export const getMailCount = createAsyncThunk(
  "/order/getMailCount",
  async () => {
    try {
      const response = await OrderService.getMailCount();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrdersData = createAsyncThunk(
  "/order/getOrdersData",
  async (order) => {
    try {
      const response = await OrderService.getOrdersData(order);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

export const getOrdersDetails = createAsyncThunk(
  "/order/getOrdersDetails",
  async (orderId) => {
    try {
      const response = await OrderService.getOrdersDetails(orderId);
      const data = await response.data;

      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteOrdersDetails = createAsyncThunk(
  "/order/deleteOrdersDetails",
  async (data) => {
    try {
      await OrderService.deleteOrdersDetails(data);
      return data.orderId;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const sendIncompletePaymentMail = createAsyncThunk(
  "/admin/send-invoice",
  async (paymentData) => {
    try {
      const response = await OrderService.sendIncompletePaymentMail({
        orderId: paymentData,
      });
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const sendRefundMail = createAsyncThunk(
  "/admin/move-to-complete-refund",
  async (refundData) => {
    try {
      const response = await OrderService.sendRefundMail({
        orderId: refundData,
      });
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const uploadVisaDocument = createAsyncThunk(
  "/admin/upload-visa-document",
  async (uploadData) => {
    try {
      const response = await OrderService.uploadVisaDocument(uploadData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const resendMail = createAsyncThunk(
  "/admin/resend-mail",
  async (mailData) => {
    try {
      const response = await OrderService.resendMail(mailData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const ChangeOrderStatus = createAsyncThunk(
  "/order/ChangeOrderStatus",
  async (orderStatus) => {
    try {
      const response = await OrderService.ChangeOrderStatus(orderStatus);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const searchByOrderId = createAsyncThunk(
  "/search/searchByOrderId",
  async (data) => {
    try {
      const response = await OrderService.searchByOrderId(data);
      return {
        navigateTo: response?.data?.data?.process_status,
        processMode: response?.data?.data?.processing_mode,
        searchOrderId: data?.searchOrderId,
        data: response?.data,
      };
    } catch (error) {
      return error.response.data;
    }
  }
);

export const defencePack = createAsyncThunk(
  "/admin/defencePack",
  async (defenceData) => {
    try {
      const response = await OrderService.defencePack(defenceData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const GetDefencePackPdf = createAsyncThunk(
  "/admin/GetDefencePackPdf",
  async (defenceData) => {
    try {
      const response = await OrderService.GetDefencePackPdf(defenceData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    error: null,
    count: {},
    mailCount: null,
    OrdersData: [],
    OrderDetails: [],
    showSideBar: true,
  },
  reducers: {
    toggleSidebar: (state) => ({
      ...state,
      showSideBar: !state.showSideBar,
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCount.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action?.payload?.result;
    });
    builder.addCase(getCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMailCount.fulfilled, (state, action) => {
      state.loading = false;
      state.mailCount = action?.payload?.data;
    });
    builder.addCase(getMailCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getOrdersData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getOrdersData.fulfilled, (state, action) => {
      state.loading = false;
      state.OrdersData = action?.payload?.data;
    });
    builder.addCase(getOrdersData.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(getOrdersDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getOrdersDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.OrderDetails = action?.payload?.data;
    });
    builder.addCase(getOrdersDetails.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteOrdersDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrdersDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.OrdersData = state.OrdersData.filter(
        (item) => action?.payload.indexOf(item.order_id) === -1
      );
    });
    builder.addCase(deleteOrdersDetails.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(searchByOrderId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(searchByOrderId.fulfilled, (state, action) => {
      state.loading = false;
      state.OrdersData = state.OrdersData.filter(
        (item) => item?.order_id === action?.payload?.searchOrderId
      );
    });
    builder.addCase(searchByOrderId.rejected, (state, action) => {
      state.loading = false;
      // state.error = action?.payload;
    });
  },
});

export const { toggleSidebar } = orderSlice.actions;

export default orderSlice.reducer;
