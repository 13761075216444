import styled from "styled-components";

export const StyleError = styled.p`
  color: red;
  float: left;
  margin-left: -34.5rem;
`;

export const MainDiv = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;

  @media (max-width: 531px) {
    display: block;
  }

 
    .user_icon{
    float: right;
    margin-top: -70px;
    padding-right: 20px
    }
  
`;

export const Styleinput = styled.input`
  color: #343a40;
  border: 1px solid;
  border-radius: 0;
  width: 50%;
  height: 60px;
  background: #fff;
  padding-left: 20px;
  box-shadow: inset 0 0 10px #051d28;
  border: 1px solid #ededed;
`;

export const Stylebutton = styled.button`
  background-color: gold;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 28%;
  opacity: 0.9;
`;

export const StyleLink = styled.a`
  color: gold;
  text-decoration: none;
`;

export const FirstDiv = styled.div`
  width: -webkit-fill-available;
`;

export const Divwrapper = styled.div`
  padding: 10rem 17rem;

  @media (max-width: 1400px) {
    padding: 10rem 9rem;
  }

  @media (max-width: 1000px) {
    padding: 10rem 2rem;
  }

  @media (max-width: 750px) {
    padding: 10rem 0.5rem;
  }

  & h2 {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: #343a40;
    font-family: dm sans, sans-serif;
    font-weight: 700;
  }

  & h5 {
    font-size: 20px;
    color: #8d2029;
    padding-bottom: 2rem;
  }
  & h6 {
    float: left;
    margin: 54px;
    margin-left: -8rem;
    border: none;
  }

  & label {
    display: block;
    font-weight: bold;
    width: max-content;

    @media (max-width: 480px) {
      width: auto;
    }
  }

  & input {
    display: block;
    color: #343a40;
    border: 1px solid;
    border-radius: 0;
    width: 100%;
    height: 60px;
    border: 1px solid #ededed;
    background: #fff;
    padding-left: 20px;
    box-shadow: inset 0 0 10px #051d28;
    margin-bottom: 25px;
  }

  & select {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
    height: 60px;
    border: 1px solid #ededed !important;
    padding-left: 20px;
    background: #fff;
    box-shadow: inset 0 0 10px #051d28;
  }

  & a {
    decoration: none;
    color: gold;
  }

  .asterisk {
    color: red;
  }

  #next_city_of_disembarkation_checkbox {
    width: 21px;
    height: 21px;
    position: relative;
    top: 5px;
    outline: 2px solid #8d2029;
    outline: 2px solid #8d2029;
    border-radius: 40px;
    border: 1px solid black;
    outline-offset: -2px;
    box-shadow: none;
    display: inline;
    // margin-left: -11.5rem;
  }
`;

export const Img = styled.img`
  width: 24px;
  height: 16px;
  margin-left: -33px;
`;

export const Formdiv = styled.form`
  margin-left: 10rem;

  & input {
    padding: 0.5em;
    border-radius: 7px;
    width: 23%;
    margin-bottom: 0.5em;
    padding: 8px;
    margin: 5px 0 22px 0;
    display: inline-block;
    background: #fff;
    border: 1px solid #ededed;
    box-shadow: inset 0 0 10px #051d28;
  }

  .toast-message {
    background: green;
    color: #fff;
    font-size: 20px;
    width: 34vw;
    padding: 30px 20px;
  }
`;

export const Savebutton = styled.button`
  background-color: #8d2029;
  color: white;
  padding: 20px 12px;
  margin: 8px 0 0 17px;
  border: none;
  cursor: pointer;
  width: 28%;
  opacity: 0.9;
  border-radius: 3px;

  &:hover {
    background-color: #212529;
    z-index: 3;
    position: relative;
  }
`;

export const Cancelbutton = styled.button`
  background-color: grey;
  color: white;
  padding: 12px 21px;
  margin: 8px 0 7px 17px;
  border: none;
  cursor: pointer;
  width: 10%;
  opacity: 0.9;
  margin-right: 9px;
  margin-left: -80rem;
  border-radius: 8px;
`;

export const Buttondiv = styled.div`
  margin-left: 5rem;
  margin-top: 3rem;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-left: 9rem;
  padding-bottom: 2px;
  width: 40rem;
  font-weight: bold;
`;

export const StyledContainer = styled.div`
  // background: aliceblue;
  padding: 40px;


`;
