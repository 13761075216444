import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../../pages/PageNotFound";
import ChangePassword from "../../../pages/Admin/Settings/ChangePassword";
import Gateway from "../../../pages/Admin/Settings/GateWay";
import ManageTeam from "../../../pages/Admin/Settings/ManageTeam";
import Login from "../../../pages/AdminLogin/Login";
import PendingOrders from "../../../pages/Admin/Orders/PendingOrders/PendingOrders";
import CompletedOrders from "../../../pages/Admin/Orders/CompletedOrders/CompletedOrders";
import ContactCustomer from "../../../pages/Admin/Orders/ContactCustomer/ContactCustomer";
import NewOrders from "../../../pages/Admin/Orders/NewOrders/NewOrders";
import PriorityOrders from "../../../pages/Admin/Orders/PriorityOrders/PriorityOrders";
import FutureOrders from "../../../pages/Admin/Orders/FutureOrders/FutureOrders";
import ViewOrders from "../../../Components/ViewOrders/ViewOrders";
import AwaitingResponse from "../../../pages/Admin/Orders/AwaitingResponse/AwaitingResponse";
import OrderHistory from "../../../pages/Admin/Orders/OrderHistory/OrderHistory";
import DeletedOrders from "../../../pages/Admin/Orders/DeletedOrders/DeletedOrders";
import RefundOrders from "../../../pages/Admin/Orders/RefundOrders/RefundOrders";
import IncompletePaymentOrders from "../../../pages/Admin/Orders/IncompletePaymentOrders/IncompletePaymentOrders";
import IncompleteOrders from "../../../pages/Admin/Orders/IncompleteOrders/IncompleteOrders";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/" element={<NewOrders />} />
        <Route path="/view-order/:orderId" element={<ViewOrders />} />
        <Route path="/recent-order" element={<NewOrders />} />
        <Route path="/pending-order" element={<PendingOrders />} />
        <Route path="/completed-order" element={<CompletedOrders />} />
        <Route path="/priority-order" element={<PriorityOrders />} />
        <Route path="/future-order" element={<FutureOrders />} />
        <Route path="/contact-customer" element={<ContactCustomer />} />
        <Route path="/awaiting-order" element={<AwaitingResponse />} />
        <Route path="/Order-history" element={<OrderHistory />} />
        <Route path="/delete-order" element={<DeletedOrders />} />
        <Route path="/refund-order" element={<RefundOrders />} />
        <Route
          path="/incomplete-payment-order"
          element={<IncompletePaymentOrders />}
        />
        <Route path="/incomplete-order" element={<IncompleteOrders />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/setting" element={<Gateway />} />
        <Route path="/manage-team" element={<ManageTeam />} />
        <Route path="/manage-team/:teamMemberId" element={<ManageTeam />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
