import React from "react";
import { Route, Routes } from 'react-router-dom';
import Home from '../../../pages/Home';
import ApplyNow from '../../../pages/ApplyNow';
import AdditionalBenefits from '../../../pages/AdditionalBenefits';
import TermsAndConditions from '../../../pages/TermsAndConditions';
import Privacypolicy from '../../../pages/Privacypolicy';
import RefundPolicy from '../../../pages/RefundPolicy';
import FAQ from '../../../pages/FAQ';
import ContactUs from '../../../pages/ContactUs';
import PageNotFound from "../../../pages/PageNotFound";


const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/apply-now' element={<ApplyNow />} />
        <Route path='/additional-benefits' element={<AdditionalBenefits />} />
        <Route path='/terms-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<Privacypolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
