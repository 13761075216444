import styled from "styled-components";

export const StyledWrapper = styled.div`
  padding: 80px 0px;

  & h2 {
    text-align: center;
  }
`;

export const StyledDiv = styled.div`
  padding: 2rem 15rem;

  & h5 {
    font-size: 20px;
    color: #8d2029;
    padding-top: 2rem;
  }

  & a {
    color: var(--bs-link-color);
    text-decoration: none;
  }

  @media (max-width: 482px) {
    padding: 2rem 8rem;
  }

  @media (max-width: 308px) {
    padding: 2rem 3.5rem;
  }
`;
