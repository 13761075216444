import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainContainer } from "./style";
import ReactDataTable from "../../../../Components/ReactDataTable/index";
import {
  deleteOrdersDetails,
  getCount,
  getOrdersData,
} from "../../../../redux/orderSlice";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { encryptVal } from "../../../../utility/utility";
import Modal from "../../../../Components/Modal/Modal";

const RefundOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RefundOrderData = useSelector((state) => state?.order?.OrdersData);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleClose = () => setShow(false);

  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData(orderId);
  };

  useEffect(() => {
    dispatch(getOrdersData("refundorder"))
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.data?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/auth/login");
        }
      });
    document.title = "Refund Orders";
  }, [dispatch, navigate]);

  const deleteOrder = (orderID) => {
    let data = {
      orderId: orderID,
      permanentDeletedFlag: false,
      updatingStatus: "Deleted",
    };
    dispatch(deleteOrdersDetails(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(getCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };
  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telephone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Refund Date",
      selector: (row) => row.refundDate,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) =>
        RefundOrderData?.length ? (
          <span>
            <Link
              to={`/admin/view-order/${encryptVal(row.id)}`}
              style={{
                textDecoration: "none",
                color: "#f96332",
              }}
            >
              View
            </Link>{" "}
            <Link
              to="#"
              onClick={() => handleShow(row.id)}
              style={{
                textDecoration: "none",
                color: "#f96332",
              }}
            >
              Delete
            </Link>
          </span>
        ) : (
          ""
        ),
    },
  ];
  const tableData = RefundOrderData;

  return (
    <MainContainer>
      <div className="col-lg-12">
        <div className="card">
          <div className="header">
            <h2>
              <strong>Refund Orders</strong>
            </h2>
          </div>
          <div className="body">
            <form>
              <input
                type="hidden"
                id="order_id"
                className="order_id"
                name="order_id"
                value=""
              />
              <ReactDataTable
                customOrdersColumns={columns}
                customOrdersData={tableData}
                buttonWrapper={"refund"}
              />
              <Modal
                show={show}
                close={handleClose}
                size={"s"}
                confirmAlert={true}
                noEvent={() => handleClose()}
                yesEvent={() => deleteOrder(selectedData)}
              ></Modal>
            </form>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default RefundOrders;
