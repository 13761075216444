import styled from "styled-components";

export const Container = styled.div.attrs({ className: "row clearfix" })`
  .card {
    transition: 0.5s;
    display: contents;
    border: 0;
    position: relative;
    width: 100%;
    background: transparent;
    border-radius: 0.55rem;
  }

  .card .header {
    color: #424242;
    padding: 20px 0;
    position: relative;
    box-shadow: none;
  }

  .card .header h2 {
    font-size: 16px;
    color: #212121;
    position: relative;
  }
  .card.card-details .body {
    box-shadow: 0 0px 11px 0 rgb(0 0 0 / 10%);
  }

  & p {
    color: red;
  }

  .label-bold {
    font-weight: bold;
  }
  .card .body {
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }

  .card .card-body {
    min-height: 190px;
  }
  .card-details .card-body {
    min-height: 0;
    padding: 0;
  }
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .card-details .card-header {
    font-weight: bold;
    color: #000000;
    background: none;
    padding: 0;
    font-size: 18px;
    border: none;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .card-details .table-bordered {
    border: 2px solid #dee2e6;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-bordered {
    border: 1px solid #dee2e6;
  }
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  table {
    border-collapse: collapse;
  }

  .table td,
  .table th {
    vertical-align: middle;
    white-space: nowrap;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6;
  }
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline .form-check {
    width: 100%;
  }

  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      width: 50px;
    }

    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
      width: 50%;
    }
    .form-inline .form-control {
      display: inline-block;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }

    .form-group .form-control,
    .input-group .form-control {
      padding: 10px 18px 10px 18px;
    }
    @media (min-width: 576px) .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

    .form-control {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #e3e3e3;
      border-radius: 30px;
      color: #2c2c2c;
      line-height: normal;
      font-size: 0.93em;
      box-shadow: none;
      height: auto;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto;
    }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: rgb(249, 101, 51);
  }

  .card-details textarea.form-control {
    margin: 0 0 15px;
    border: 1px solid #e3e3e3;
  }

  .card .card-body {
    min-height: 190px;
  }
  textarea.form-control {
    max-width: 100%;
    padding: 10px 10px 0 0;
    resize: none;
    border: none;
    border-bottom: 1px solid #e3e3e3;
    border-radius: 0;
    line-height: 2;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }

  .blue-btn {
    background-color: #18f;
    color: #ffffff;
    border: currentColor;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 10px;
    display: inline-block;
    margin: 5px 1px;
    width: fit-content;
  }

  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }

  .btn:hover {
    color: #212529;
    text-decoration: none;
  }

  .blue-btn:hover,
  .blue-btn:focus {
    background-color: #0b3157;
    color: #fff;
  }

  .btn-success {
    background-color: #18ce0f;
    color: #fff;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
`;
