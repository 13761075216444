import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 100px 0px;
  background-color: #f7f7f7;

  & h2 {
    text-align: center;
  }
`;

export const StyledChild = styled.div`
  padding: 2rem 15rem;

  @media (max-width: 1000px) {
    padding: 2rem 8rem;
}

@media (max-width: 665px) {
  padding: 2rem 6rem;
}

@media (max-width: 400px) {
  padding: 2rem 3rem;
}

@media (max-width: 251px) {
  padding: 2rem 1rem;
}
`;

export const StyledDiv = styled.div`
  & h5 {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: #343a40;
    font-family: dm sans, sans-serif;
    font-weight: 700;
  }

  & p {
    font-size: 16px;
    line-height: 26px;
    padding: 0;
    margin: 0;
    color: #000;
    font-family: "DM Sans", sans-serif;
    text-align: justify;
  }

  headerTitle {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: #343a40;
    font-family: dm sans, sans-serif;
    font-weight: 700;
  }
  .accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
  }
  .accordion-button:focus {
    border-color: none;
    outline: none;
    box-shadow: none;
  }
  .accordion-header {
    margin-bottom: 0px;
    margin-top: 15px;
  }

  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "+";
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }

  .accordion-button:not(.collapsed)::after {
    // background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }
`;
