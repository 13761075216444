import styled from "styled-components";

export const EmailContainer = styled.div`
  padding: 10px;
  margin: 5px;
  border: solid 2px #000;

  & hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid black;
    opacity: unset;
  }
  & a {
    text-decoration: none;
    color: #f96332;
  }

  .upload-doc-link {
    border: 1px solid black;
    border-color: #18f;
    padding: 11px;
    border-radius: 14px;
    background-color: #18f;
    color: white;
  }

  & button {
    color: #fff;
    background: #0d6efd;
    border: none;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .flex-class {
    display: flex;
  }

  & img,
  svg {
    vertical-align: middle;
    width: 10rem;
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid black;
    /* opacity: .25; */
  }
`;
