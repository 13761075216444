import styled from "styled-components";

export const MainContainer = styled.div.attrs({ className: "col-lg-12" })`
  .card {
    transition: 0.5s;
    border: 0;
    position: relative;
    width: 100%;
    background: 0 0;
    border-radius: 0.55rem;
  }

  .card .header {
    color: #424242;
    padding: 20px 0;
    position: relative;
    box-shadow: none;
  }
  .card .header h2 {
    font-size: 16px;
    color: #212121;
    position: relative;
  }

  .card .body:hover {
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
    transition: all 0.2s ease-in-out;
  }
  .body {
    font-size: 14px;
    padding: 25px;
    margin: 10px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }
  .body {
    box-shadow: 0 0 18px rgb(0 0 0 / 8%);
  }

  .card .body {
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }

  & a {
    text-decoration: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  border-width: 2px;
  font-weight: 400;
  font-size: 0.93em;
  line-height: 1.35em;
  margin: 5px 1px;
  border: none;
  border-radius: 0.1875rem;
  padding: 11px 22px;
  cursor: pointer;
  background-color: #fff;
  color: #fff;
  gap: 1rem;

  & button.btn {
    background-color: #18ce0f;
    border-color: #18ce0f;
  }
`;
