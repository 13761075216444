import styled from "styled-components";

export const SearchField = styled.div`
  float: right;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  border-width: 2px;
  font-weight: 400;
  font-size: 0.93em;
  line-height: 1.35em;
  margin: 5px 1px;
  border: none;
  border-radius: 0.1875rem;
  padding: 11px 22px;
  cursor: pointer;
  background-color: #fff;
  color: #fff;
  gap: 1rem;

  & button.btn {
    background-color: #18ce0f;
    border-color: #18ce0f;
  }
`;
