import styled from "styled-components";

export const StyledHeader = styled.div`
  background: #343a40;
  display: flex;
  align-items: center;
 // padding: 20px 0;
  width: 100%;
  display: flex;
  // margin-top: 3rem;
  gap: 10px;

  & select{
    padding: 19px 22px;
      color: #fff;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  appearance: none;
  background: #8d2029;


  @media (max-width: 230px) {
    padding: 19px 10px;
  }
  // @media (max-width: 1200px) {
  //   padding: 19px 22px;

  // }

  // }

  // @media (max-width: 1200px) {
  //   width: 120%;

  // }
  
`;


export const StyledChild = styled.div`
color: #fff;
 padding-left: 65rem;
@media (max-width: 1200px) {
  padding-left: 20rem;
}

@media (max-width: 710px) {
  padding-left: 8rem;
}

@media (max-width: 310px) {
  padding-left: 3rem;
}

@media (max-width: 230px) {
  padding-left: 2rem;
}

`;

