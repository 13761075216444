import React from "react";
import { StyledNav } from "./style";
import ArrivalCard from '../../../../assests/img/logo.png';
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <StyledNav>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to={'/'}>
          <img src={ArrivalCard} alt="arrivalcard"/>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link active" aria-current="page" to={'/'}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/apply-now'}>
                  Apply Now
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/additional-benefits'}>
                  Additional Benefits
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/terms-conditions'}>
                  Terms & Conditions{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/privacy-policy'}>
                  Privacy Policy
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/refund-policy'}>
                  Refund Policy
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/faq'}>
                  FAQ
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/contact-us'}>
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </StyledNav>
  );
};

export default Navbar;
