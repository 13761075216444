import React from "react";
import { StyledChild, StyledContainer, StyledDiv } from "./style";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

// import { FaPlus } from "react-icons/fa";

const FAQ = () => {
  return (
    <StyledContainer size="large">
      <h2>FAQ</h2>
      <StyledChild>
        <StyledDiv>
          <MDBAccordion initialActive={1}>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="What is the SG Arrival Card?"
              className="fa-plus:before"
            >
              {" "}
              <p>
                Singapore Arrival Cards are electronic landing cards that allow
                people from visa-required countries and visa-exempt countries to
                submit their arrival information and trip details to Singapore.
                The SG Arrival Card for Singapore is meant to replace the
                paper-based disembarkation/embarkation (D/E) card. An easy and
                straightforward online application can be completed to get the
                Singapore Arrival Card. You don't need to go to an embassy or
                consulate before visiting Singapore.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="How long is the SG Arrival Card authorized stay?"
            >
              <p>
                SG Arrival Cards are issued to individuals whose nationality is
                determined by the purpose of their travel, and the length of
                stay they are authorized to stay in Singapore. Please keep in
                mind that the SG Arrival Cards are not visas.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="What type of entry does the SG Arrival Card allow?"
            >
              <p>
                An SG Arrival Card allows one citizen to enter the country once.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={4}
              headerTitle="How long is the SG Arrival Card valid for?"
            >
              <p>
                Your SG Arrival Card is good for one entry starting on the date
                you want to arrive.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={5}
              headerTitle="When do I need to apply for the SG Arrival Card?"
            >
              <p>
                Travelers can request the SG Arrival Card online at any time.
                The SG Arrival Card will be sent to your email address in time
                for your trip, taking into account your arrival date in
                Singapore.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={6}
              headerTitle="Do I need an SG Arrival Card and an approved Visa to enter Singapore?"
            >
              <p>Most travelers need an SG Arrival Card to enter Singapore.</p>

              <br />
              <p>
                If a citizen from more than one nationality wishes to enter the
                country, he or she must have a Singapore Arrival Card and also
                an approved Singapore visa. These documents can be obtained
                separately by the individual.
              </p>

              <p>
                For travelers from the following countries, there is a
                requirement to obtain both a SG Arrival Card and a Singapore
                visa, both of which must be approved:
              </p>

              <ul>
                An evisa: Afghanistan, Algeria, Bangladesh, Egypt, Iran, Iraq,
                Jordan, Libya, Mali, Morocco, Nigeria, Pakistan, Saudi Arabia,
                Somalia, Sudan, Syrian Arab Republic, Tajikistan, Tunisia, Yemen
              </ul>
              <ul>
                A consular visa: Armenia, Azerbaijan, Belarus, People’s Republic
                of China, Georgia, India, Kyrgyzstan, People’s Republic of
                Korea, Kazakhstan, Moldova, Malaysia, Russia, Turkmenistan,
                Ukraine, Uzbekistan.
              </ul>

              <p>
                Please contact your nearest Singapore Consulate or Embassy for
                more information about visa processing.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={7}
              headerTitle="I want to apply online for an SG Arrival Card. What do I need?"
            >
              <p>
                In order to apply online for a Singapore Arrival Card,
                applicants need:
              </p>
              <br />

              <ul>
                A current passport with more than six (6) months validity from
                the date of arrival. The passport should have at least one (1)
                blank page for border crossing stamps
              </ul>
              <ul>
                Accommodation information, such as name and address of hotel or
                hostel, etc.
              </ul>
              <ul>
                Transportation information such as flight details, intended date
                of arrival, etc.
              </ul>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={8}
              headerTitle="How do I submit a Singapore health declaration for COVID-19?"
            >
              <p>
                The COVID-19 regulations have made it mandatory for all incoming
                passengers to fill out a health declaration form. This applies
                to Singapore citizens as well.
              </p>

              <p>
                Since the SG Arrival Card application and health declaration are
                integrated, you can fill it out online.
              </p>

              <p>
                Travelers can submit their Singapore health declaration anytime
                during their Arrival Card application.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={9}
              headerTitle="Which are the designated arrival ports of entry in Singapore?"
            >
              <p>
                Singapore Arrival Cards are valid at these designated entry
                points:
              </p>

              <ul>Singapore Changi Airport - SIN</ul>
              <ul>Woodlands checkpoint</ul>
              <ul>Tuas checkpoint</ul>
              <ul>Singapore Cruise Centre</ul>
              <ul>Tanah Merah Ferry Terminal</ul>
              <ul>Changi Point Ferry Terminal</ul>
              <ul>Changi Ferry Terminal</ul>
              <p>
                Vaccinated Singaporeans and Long-Term Pass holders may not need
                a SG Arrival Card if they're entering through Woodlands and
                Tuas.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={10}
              headerTitle="How do I submit a Singapore health declaration for COVID-19?"
            >
              <p>
                The COVID-19 regulations have made it mandatory for all incoming
                passengers to fill out a health declaration form. This applies
                to Singapore citizens as well.
              </p>

              <p>
                Since the SG Arrival Card application and health declaration are
                integrated, you can fill it out online.
              </p>

              <p>
                Travelers can submit their Singapore health declaration anytime
                during their Arrival Card application.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={11}
              headerTitle="Do minors need an SG Arrival Card?"
            >
              <p>
                The SG Arrival Card application must be submitted separately for
                each minor, regardless of whether they're in an adult's passport
                or not.
              </p>

              <p>
                At border control, underage citizens must present their SG
                Arrival Card, whether they have their own passport or are on
                their parents'.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={12}
              headerTitle="How long will it take for my SG Arrival Card to be processed?"
            >
              <p>
                Singapore Arrival Cards are processed within 1 – 72 hours one
                after the application form has been submitted and all requested
                information has been provided.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={13}
              headerTitle="What should I do after I receive my SG Arrival Card?"
            >
              <p>
                As soon as you get your approved SG Arrival Card, you'll just
                need to show your passport to immigration upon arrival in
                Singapore. Singapore's immigration system will already have your
                electronic arrival card.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={14}
              headerTitle="What do I do if I have two passports?"
            >
              <p>
                When you have two (2) passports because you're dual national,
                you must pick the one you'll use to enter Singapore.
              </p>

              <p>
                SG Arrival Card applications must include the passport
                information of the chosen passport. There can't be any
                inconsistencies between the SG Arrival Card and the passport
                information provided.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={15}
              headerTitle="How can I find out my SG Arrival Card application status?"
            >
              <p>
                Applicants will get updates and notifications via sms, whatsapp
                and email to the address they provide on the application.
              </p>

              <p>
                You can contact us here if you have questions or want to check
                the status of your Singapore Arrival Card Online application.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={16}
              headerTitle="How can I make the application payment?"
            >
              <p>
                We accept all major payment methods. The application fee can be
                paid online with a credit card or debit card. If you run into
                any problems, just email inquiries@sg-arrivalcard.online.
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={17}
              headerTitle="What is the CVV/CVC/CVC2 number on my credit or debit card?"
            >
              <p>
                You'll find the CVV/CVC/CVC2 number on the back of your credit
                or debit card, right after the account number.
              </p>

              <p>
                On American Express cards, the CVV number is above the account
                number.
              </p>

              <p>
                If you have any problems, please email
                inquiries@sg-arrivalcard.online.{" "}
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={18}
              headerTitle="I submitted my application for the SG Arrival Card and I still haven’t received a confirmation, what should I do?"
            >
              <p>
                When the application is submitted and payment is made, the
                applicant will get a confirmation email. Be aware that it may
                sometimes get redirected to your spam folder or junk mail
                folder.
              </p>
              <p>
                You should check your inbox before contacting us at
                inquiries@sg-arrivalcard.online.{" "}
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={19}
              headerTitle="What do I do if my SG Arrival Card is denied?"
            >
              <p>
                An SG Arrival Card is entirely up to Singapore's Immigration &
                Checkpoints Authority (ICA).
              </p>
              <p>
                If your Singapore Arrival Card application is rejected, contact
                us for a full refund
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={20}
              headerTitle="What do I do if my SG Arrival Card is denied?"
            >
              <p>
                An SG Arrival Card is entirely up to Singapore's Immigration &
                Checkpoints Authority (ICA).
              </p>
              <p>
                If your Singapore Arrival Card application is rejected, contact
                us for a full refund
              </p>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={21}
              headerTitle="I’ve realized that some information on my SG Arrival Card is incorrect, what should I do?"
            >
              <p>
                Applicants should check the information they provide in their
                applications carefully before submitting them. Take a few
                minutes to revise your info before submitting it.{" "}
              </p>

              <p>
                It's important to enter all information in the application form
                exactly as it appears on the traveler's passport so that we can
                process the application.{" "}
              </p>

              <p>
                If you have already submitted your Electronic Arrival Card
                application form and later realized that it contains incorrect
                information, please contact us immediately at
                inquiries@sg-arrivalcard.online.{" "}
              </p>
            </MDBAccordionItem>
          </MDBAccordion>
        </StyledDiv>
      </StyledChild>
    </StyledContainer>
  );
};

export default FAQ;
