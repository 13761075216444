import React from "react";
import { StyledFooter } from './style';

const Footer = () => {
  return (
    <StyledFooter>
      <p>© 2022 sg-arrivalcard.online. All Rights Reserved </p>
    </StyledFooter>
    // <div>
    //   <footer className="footer-sec">
    //     <div className="container"></div>
    //     <div className="container-fluid">
    //       <div className="row bottom-footer">
    //         <div className="col-lg-12 text-center">
    //         </div>
    //       </div>
    //     </div>
    //   </footer>
    // </div>
  );
};

export default Footer;
