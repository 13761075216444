import React, { useEffect, useState } from "react";
import ReactDataTable from "../../../../Components/ReactDataTable";
import { Container, StyledContainer } from "./style";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getTeamMemberList,
  deleteTeamMember,
  teamMemberRegistration,
  getTeamMemberById,
  updateTeamMember,
} from "../../../../redux/manageTeamSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import Modal from "../../../../Components/Modal/Modal";

const ManageTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TeamMembersList = useSelector(
    (state) => state?.manageTeam?.teamMembers
  );
  const teamMemberData = useSelector((state) => state?.manageTeam?.teamMember);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedData, setSelectedData] = useState([]);
  const { teamMemberId } = useParams();
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData(orderId);
  };

  useEffect(() => {
    if (teamMemberId) {
      dispatch(getTeamMemberById(teamMemberId));
    }
    dispatch(getTeamMemberList()).then((res) => {
      if (res.status === 1) {
        toast.success(`${res.message}`, {
          className: "toast-message",
        });
      } else if (res?.payload?.status === 401) {
        toast.error(`${res?.payload?.message}`, {
          className: "toast-message",
        });
        localStorage.removeItem("user");
        navigate("/auth/login");
      }
    });

    document.title = "ManageTeam";
  }, [dispatch, navigate, teamMemberId]);

  let defaultInitialValues = {
    name: "",
    email: "",
    password: "",
    type: "",
    profilePath: "",
  };
  const teamMemberSchema = yup.object({
    name: yup.string().required("Please enter your name"),
    email: yup.string().required("Please enter your email"),
    password: yup.string().required("Please enter your password"),
    type: yup.string().required("Please select type"),
    profilePath: teamMemberId
      ? null
      : yup.string().required("Please upload profile picture"),
  });
  if (
    teamMemberId !== null &&
    teamMemberId !== undefined &&
    teamMemberData !== null
  ) {
    defaultInitialValues = {
      name: teamMemberData?.name ? teamMemberData?.name : "",
      email: teamMemberData?.email ? teamMemberData?.email : "",
      password: teamMemberData?.password ? teamMemberData?.password : "",
      type: teamMemberData?.type ? teamMemberData?.type : "",
      profilePath: teamMemberData?.profile_path
        ? teamMemberData?.profile_path
        : "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);
  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Password",
      selector: (row) => row.password,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Action",
      selector: (row) =>
        TeamMembersList?.length ? (
          <span>
            <Link
              to={`/admin/manage-team/${row.id}`}
              style={{
                textDecoration: "none",
                color: "#f96332",
              }}
            >
              View
            </Link>{" "}
            <Link
              to=""
              onClick={() => handleShow(row.id)}
              style={{
                textDecoration: "none",
                color: "#f96332",
              }}
            >
              Delete
            </Link>
          </span>
        ) : (
          ""
        ),
    },
  ];

  const tableData = TeamMembersList;

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: teamMemberId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: teamMemberSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("name", values.name);
      data.append("email", values.email);
      data.append("password", values.password);
      data.append("type", values.type);
      data.append("teamMemberProfile", values.profilePath);

      if (teamMemberId) {
        data.append("selectedProfilePath", teamMemberData?.profile_path);
        data.append("tmId", teamMemberId);
      }
      teamMemberId
        ? dispatch(updateTeamMember(data))
            .unwrap()
            .then((res) => {
              resetForm();
              if (res.status === 1) {
                toast.success(`${res.message}`, {
                  className: "toast-message",
                });
              } else {
                toast.error(`${res.message}`, {
                  className: "toast-message",
                });
              }
            })
            .catch((err) => {
              toast.error(`${err.message}`, {
                className: "toast-message",
              });
            })
        : dispatch(teamMemberRegistration(data))
            .unwrap()
            .then((res) => {
              resetForm();
              if (res.status === 1) {
                toast.success(`${res.message}`, {
                  className: "toast-message",
                });
              } else {
                toast.error(`${res.message}`, {
                  className: "toast-message",
                });
              }
            })
            .catch((err) => {
              toast.error(`${err.message}`, {
                className: "toast-message",
              });
            });
    },
  });

  const deleteTeamMemberRow = (memberID) => {
    let data = {
      teamMemberId: [memberID],
    };
    dispatch(deleteTeamMember(data))
      .unwrap()
      .then((res) => {
        handleClose();
        if (res[0] !== null) {
          toast.success("Team member deleted successfully.", {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          className: "toast-message",
        });
      });
  };
  return (
    <>
      <StyledContainer>
        <Container>
          <div className="card">
            <div className="header">
              <h2>
                <strong>Manage Team</strong>
              </h2>
            </div>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit}>
              <div className="row clearfix">
                <div className="col-md-6">
                  <label>
                    <strong>Name</strong>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Enter Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p>{errors.name}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Email ID</strong>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Email"
                      autoComplete="false"
                    />
                    <p>{errors.email}</p>
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-md-6">
                  <label>
                    <strong>Password</strong>
                  </label>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control required"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Password"
                      autoComplete="false"
                    />
                    <p>{errors.password}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Member Type</strong>
                  </label>
                  <div className="form-group">
                    <select
                      name="type"
                      className="form-control"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Type</option>
                      <option value="Team">Team</option>
                      <option value="Telecaller">Telecaller</option>
                      <option value="Night Staff">Night Staff</option>
                    </select>
                    <p>{errors.type}</p>
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-md-6">
                  <label>
                    <strong>Upload Profile Picture</strong>
                  </label>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control required"
                      id="profilePath"
                      name="profilePath"
                      accept="image/*"
                      onChange={(e) =>
                        setFieldValue("profilePath", e.currentTarget.files[0])
                      }
                    />
                    <p>{errors.profilePath}</p>
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-md-6">
                  <div className="form-group">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Container>
      </StyledContainer>

      <StyledContainer>
        <div className="col-lg-12">
          <div className="card">
            <div className="header">
              <h2>
                <strong>Manage Team List</strong>
              </h2>
            </div>
          </div>{" "}
          <div className="body">
            <ReactDataTable
              customColumns={columns}
              customData={tableData}
              buttonWrapper={null}
            />
          </div>
        </div>
        <Modal
          show={show}
          close={handleClose}
          size={"s"}
          confirmAlert={true}
          noEvent={() => handleClose()}
          yesEvent={() => deleteTeamMemberRow(selectedData)}
        ></Modal>
      </StyledContainer>
    </>
  );
};

export default ManageTeam;
