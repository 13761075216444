import React from "react";
import ArrivalCard from "../../../assests/img/logo.png";
import { Link } from "react-router-dom";
import { EmailContainer } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utility/utility";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.OrderDetails);

  return (
    <EmailContainer>
      <div>
        <img
          src={ArrivalCard}
          alt="Arrivalcard"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </div>
      <hr />
      <p>Dear GENESES P NIEVES</p>
      <p>
        We would like to inform you that your application has been successfully
        approved.
      </p>
      <p className="flex-class">
        Please download your&nbsp;<b>SINGAPORE ARRIVAL CARD</b>&nbsp;from the
        link below.
      </p>
      <h6>
        Username :<Link to="#">{OrderDetails.email}</Link>
      </h6>
      <p className="flex-class">
        <b>Order ID : </b>
        {OrderDetails.order_id}
      </p>
      <Link
        className="upload-doc-link"
        to={`https://sg-arrivalcard.online/track.php?order_id=${encryptVal(
          OrderDetails.order_id
        )}`}
      >
        Click Here
      </Link>
      <br />
      <br />
      <p>
        The SG Arrival Card is only valid for one trip. Travellers need to
        submit an SG Arrival Card for each trip to Singapore. Upon arrival,
        <b>
          travellers are required to show their acknowledgement email on their
          mobile device or a printout copy to ICA officers.
        </b>
      </p>
      <p>
        As part of Singapore Vaccination-differentiated Safe Management Measures
        (VDS), you must be vaccinated to participate in certain activities in
        Singapore (e.g. dining in F&B establishments, entering shopping malls,
        attending certain events etc.).{" "}
      </p>
      <ul>
        <h6>Important! Keep in mind:</h6>
        <li>
          You can submit your electronic health declaration 3 days prior to your
          date of arrival in Singapore.
        </li>
        <li>Section 55, Infectious Diseases Act -You are required to:</li>
        <li>
          Complete every item in this health declaration card, and Submit it to
          the Officer-in-Charge upon disembarkation.
        </li>
        <li>
          You may be subject to a medical examination upon arrival in Singapore.
        </li>
        <li>
          Section 15, Infectious Diseases Act -You may be isolated/quarantined
          if:
        </li>
        <li>
          You are infected or suspected to be infected with an infectious
          disease; or
        </li>
        <li>You are or suspected to be a contact of such a person.</li>
        <li>
          If you are unwell, please inform the crew onboard, where applicable,
          or any appropriate personnel immediately.
        </li>
      </ul>
      <ul>
        <h6>Penalty</h6>
        <li>
          Section 55(8), Infectious Diseases Act -Failure to complete / submit
          this card: Fine of up to $10,000 and/or imprisonment of up to 6 months
          Fine of up to S$20,000 and/or imprisonment of up to 12 months
          (subsequent offence).
        </li>
        <li>
          Section 64(b), Infectious Diseases Act - Providing false information:
          Fine of up to $10,000 and/or imprisonment of up to 6 months Fine of up
          to S$20,000 and/or imprisonment of up to 12 months (subsequent
          offence).
        </li>
      </ul>
      <p>
        {" "}
        Please make sure you check the official requirements and health
        regulations before travelling to your destination as travel restrictions
        may apply in some cases.
      </p>
      <p>
        We remain at your disposal for any further questions or assistance you
        may need regarding your Singapore Arrival Card.
      </p>
      <p className="flex-class">
        <b> Customer Service Number :</b>
        +1 (407) 955 9631
      </p>
      <h6>Important Notice:</h6>
      <p>
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at
        <Link to="#"> inquiries@sg-arrivalcard.online</Link>,where we will be
        happy to assist you or alternatively click on one of the links below for
        more information.
      </p>
      <p>
        You can access the terms and conditions you agreed to by clicking here{" "}
        <Link to="https://sg-arrivalcard.online/terms.pdf" target="_blank">
          click here.
        </Link>
      </p>
      <p>Thank you for using our services</p>
      <p> Regards,</p>
      <p>Processing Department</p>
      <p>
        {" "}
        <Link to="https://sg-arrivalcard.online/terms.php" target="_blank">
          Terms{" "}
        </Link>
        |{" "}
        <Link to="https://sg-arrivalcard.online/privacy.php" target="_blank">
          Privacy
        </Link>{" "}
        |
        <Link to="https://sg-arrivalcard.online/contact.php" target="_blank">
          {" "}
          Contact{" "}
        </Link>
        |{" "}
        <Link to="https://sg-arrivalcard.online/refund.php" target="_blank">
          Refund
        </Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
