import React, { useEffect } from "react";
import { MainContainer } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../redux/authSlice";
import { encryptVal } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialValues = {
  newPassword: "",
};

const changePasswordSchema = yup.object({
  newPassword: yup.string().min(6).required("Please enter your New Password"),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Change Password";
  }, []);
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      dispatch(changePassword(encryptVal(values.newPassword)))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            navigate("/auth/login");
          } else if (res.status === 401) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
            localStorage.removeItem("user");
            navigate("/auth/login");
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });
  return (
    <MainContainer>
      <div className="col-lg-12">
        <div className="card">
          <div className="header">
            <h2>
              <strong>Change Password</strong>
            </h2>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Enter New password</h5>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    className="form-control"
                    placeholder="Enter your password"
                    autoComplete="off"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p>{errors.newPassword}</p>
                </div>
                <div className="col-sm-12">
                  <br />
                  <button type="submit">Update Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ChangePassword;
