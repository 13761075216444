import React, { useEffect, useState } from "react";
import { BlockHeader, StyledBody, StyledCard, Card } from "./style";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import NewOrder from "../../../../assests/images/new_order.svg";
import PendingOrder from "../../../../assests/images/pending_order.svg";
import CompletedOrder from "../../../../assests/images/completed_order.svg";
import ContactCustomer from "../../../../assests/images/contact_customer.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCount, searchByOrderId } from "../../../../redux/orderSlice";
import { titleCase } from "../../../../utility/utility";
import { toast } from "react-toastify";

const AdminNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [orderId, setOrderId] = useState("");
  const count = useSelector((state) => state?.order?.count);
  const token = localStorage.getItem("user");

  useEffect(() => {
    if (token) {
      dispatch(getCount());
    }
  }, [dispatch,token]);

  let heading = titleCase(location.pathname.slice(7).replaceAll("-", " "));
  heading =
    heading === ""
      ? "New Order"
      : heading.includes("View Order")
      ? "View Order"
      : heading.includes("Manage Team")
      ? "Manage Team"
      : titleCase(location.pathname.slice(7).replaceAll("-", " "));

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      let data = {
        searchOrderId: orderId,
      };
      dispatch(searchByOrderId(data))
        .unwrap()
        .then((res) => {
          if (res.data.status === 1) {
            if (res.navigateTo === "Awaiting") {
              navigate("/admin/awaiting-order");
            } else if (
              res.navigateTo === "Complete Refunds" ||
              res.navigateTo === "Refund"
            ) {
              navigate("/admin/refund-order");
            } else if (res.navigateTo === "Deleted") {
              navigate("/admin/delete-order");
            } else if (
              res.navigateTo === "New" &&
              res.processMode === "Regular Processing"
            ) {
              navigate("/admin/recent-order");
            } else if (
              res.navigateTo === "New" &&
              res.processMode === "Priority Processing"
            ) {
              navigate("/admin/priority-order");
            } else if (
              res.navigateTo === "New" &&
              res.processMode === "Future Order"
            ) {
              navigate("/admin/future-order");
            } else if (res.navigateTo === "Pending") {
              navigate("/admin/pending-order");
            } else if (res.navigateTo === "Completed") {
              navigate("/admin/completed-order");
            } else if (res.navigateTo === "Contact") {
              navigate("/admin/contact-customer");
            } else if (
              res.navigateTo === null &&
              (res.processMode === "Regular Processing" ||
                res.processMode === "Priority Processing")
            ) {
              navigate("/admin/incomplete-payment-order");
            } else if (res.navigateTo === null && res.processMode === null) {
              navigate("/admin/incomplete-order");
            }
          } else if (res.data.status === 0) {
            toast.error(`${res?.data?.message}`, {
              className: "toast-message",
            });
          }
        });
    }
  };
  return (
    <>
      <BlockHeader>
        <div className="row clearfix">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h2>{heading}</h2>
            <ul className="breadcrumb padding-0">
              <li className="breadcrumb-item" style={{ marginTop: "-2px" }}>
                <Link to="/admin">
                  <AiFillHome style={{ color: "#f96533" }} />
                </Link>
              </li>
              <li className="breadcrumb-item active">{heading}</li>
            </ul>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="input-group m-b-0">
              <input
                type="text"
                className="form-control"
                id="search-id"
                name="search"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                placeholder="Search..."
                onKeyDown={handleKeyDown}
              />
              <span className="input-group-addon">
                <i className="zmdi zmdi-search"></i>
              </span>
            </div>
          </div>
        </div>
      </BlockHeader>
      <StyledBody>
        <StyledCard>
          <Card>
            <div className="card text-center">
              <NavLink to="/admin/recent-order">
                <div className="body" data-target="neworder">
                  <p className="m-b-20">
                    <img src={NewOrder} alt="newOrder" />
                  </p>
                  <span>New Orders</span>
                  <h3
                    className="m-b-10 number count-to"
                    data-from="0"
                    data-to=""
                  >
                    {count?.newOrdersCount}
                  </h3>
                </div>
              </NavLink>
            </div>
          </Card>

          <Card>
            <div className="card text-center">
              <NavLink to="/admin/priority-order">
                <div className="body" data-target="priorityorder">
                  <p className="m-b-20">
                    <img src={NewOrder} alt="priorityOrder" />
                  </p>
                  <span>Priority Orders</span>
                  <h3
                    className="m-b-10 number count-to"
                    data-from="0"
                    data-to=""
                    data-speed="2000"
                    data-fresh-interval="700"
                  >
                    {count?.priorityOrdersCount}
                  </h3>
                </div>
              </NavLink>
            </div>
          </Card>

          <Card>
            <div className="card text-center">
              <NavLink to="/admin/future-order">
                <div className="body" data-target="futureorder">
                  <p className="m-b-20">
                    <img src={NewOrder} alt="futureOrder" />
                  </p>
                  <span>Future Orders</span>
                  <h3
                    className="m-b-10 number count-to"
                    data-from="0"
                    data-to=""
                    data-speed="2000"
                    data-fresh-interval="700"
                  >
                    {count?.futureOrdersCount}
                  </h3>
                </div>
              </NavLink>
            </div>
          </Card>

          <Card>
            <div className="card text-center">
              <NavLink to="/admin/pending-order">
                <div className="body" data-target="pendingorder">
                  <p className="m-b-20">
                    <img src={PendingOrder} alt="pendingOrder" />
                  </p>
                  <span>Pending Orders</span>
                  <h3
                    className="m-b-10 number count-to"
                    data-from="0"
                    data-to=""
                    data-speed="2000"
                    data-fresh-interval="700"
                  >
                    {count?.pendingOrdersCount}
                  </h3>
                </div>
              </NavLink>
            </div>
          </Card>

          <Card>
            <div className="card text-center">
              <NavLink to="/admin/completed-order">
                <div className="body" data-target="completedorder">
                  <p className="m-b-20">
                    <img src={CompletedOrder} alt="completedOrder" />
                  </p>
                  <span>Completed Orders</span>
                  <h3
                    className="m-b-10 number count-to"
                    data-from="0"
                    data-to=""
                    data-speed="2000"
                    data-fresh-interval="700"
                  >
                    {count?.completedOrdersCount}{" "}
                  </h3>
                </div>
              </NavLink>
            </div>
          </Card>

          <Card>
            <div className="card text-center">
              <NavLink to="/admin/contact-customer">
                <div className="body" data-target="contactcustomerorder">
                  <p className="m-b-20">
                    <img src={ContactCustomer} alt="ContactCustomer" />
                  </p>
                  <span>Contact Customer</span>
                  <h3
                    className="m-b-10 number count-to"
                    data-from="0"
                    data-to=""
                    data-speed="2000"
                    data-fresh-interval="700"
                  >
                    {count?.contactCustomerOrdersCount}{" "}
                  </h3>
                </div>
              </NavLink>
            </div>
          </Card>
        </StyledCard>
      </StyledBody>
    </>
  );
};

export default AdminNavbar;
